import React, { useRef } from 'react'
import classNames from 'classnames/dedupe'

import {
    CheckboxIcon,
    Counter,
    Input,
    Select
} from '@varta/digital-styleguide/build/src/components/02.molecules'

import { useEnergyDemand } from '../../redux/data/hooks/useEnergyDemand'

import { ENERGY_GPS, ENERGY_LIGHTS, ENERGY_RADIO } from '../../data/strings'
import { hourOptions } from '../../data/consts'

import './_index.scss'

const CLASSNAME = 'varta-leisure-energy-consumer'
const cnRow = `${ CLASSNAME }__row`
const cnCol = `${ CLASSNAME }__col`
const cnColQuarter = `${ cnCol } ${ cnCol }--quarter`
const cnCenter = `${ cnCol } ${ cnCol }--center`
const cnMargin = `${ CLASSNAME }__margin`
const cnFullWidth = `${ CLASSNAME }__full-width`

/**
 * 
 * @returns 
 */
export default function EnergyConsumer ({
    i18n,
    application,
    iconId,
    value,
    selected,
    inputElementType,
    inputType,
    inputValue,
    additionalElementType,
    additionalValue
}) {

    const {
        addEnergyDemand,
        toggleEnergyDemand
    } = useEnergyDemand(application)

    const formRef = useRef()

    /**
     * toggle valid flag for a pre-set energy demand
     * 
     * @param {string} key
     */
     function handleDemandClicked (key) {
        toggleEnergyDemand(key, !selected)
        if (!selected) {
            const myForm = formRef.current
            const child = myForm.querySelector(key === ENERGY_RADIO || key === ENERGY_GPS ? `select[name=${ key }]` : `input[name=${ key }]`)
            const child2 = key === ENERGY_LIGHTS ? myForm.querySelector(`select[name=${ key }-select]`) : null
            handleDemandSpecified(key, {
                device: key,
                input: child.value,
                additional: child2 ? child2.value : null
            })
        }
    }

    /**
     * handle value change for a valid pre-set energy demand
     * @param {string} demand 
     * @param {object} data
     */
    function handleDemandSpecified (demand, data) {
        addEnergyDemand(demand, data)
    }

    return (
        <div className={ classNames(cnRow, cnMargin) } ref={ formRef }>
            <div className={ classNames(cnCol) }>
                <CheckboxIcon
                    title={ i18n[value].device }
                    iconId={ iconId }
                    value={ value }
                    selected={ selected }
                    onChange={ handleDemandClicked }
                />
            </div>
            {inputElementType === 'input' && (
                <div className={ classNames(cnColQuarter, cnCenter) }>
                    <Input
                        title={ i18n[value].unit }
                        placeholder={ i18n[value].placeholder }
                        controlledValue={ inputValue }
                        disabled={ !selected }
                        onChange={v => 
                            handleDemandSpecified(value, {
                                device: value,
                                input: v
                        })}
                        // type={ inputType }
                        type='number'
                        name={ value }
                        className={ cnFullWidth }
                    />
                </div>
            )}
            {inputElementType === 'counter' && (
                <div className={ classNames(cnColQuarter, cnCenter) }>
                    <Counter
                        title={ i18n[value].unit }
                        disabled={ !selected }
                        value={ inputValue }
                        onChange={ v =>
                            handleDemandSpecified(value, {
                                device: value,
                                input: v
                            })
                        }
                        min={ 0 }
                        max={ 30 }
                        name={ value }
                    />
                </div>
            )}
            {inputElementType === 'select' && (
                <div className={ classNames(cnColQuarter, cnCenter) }>
                    <Select
                        className={ cnFullWidth }
                        title={ i18n[value].unit }
                        placeholder={ i18n[value].placeholder }
                        controlledValue={ inputValue }
                        value={ inputValue }
                        disabled={ !selected }
                        options={ hourOptions }
                        onChange={ v =>
                            handleDemandSpecified(value, {
                                device: value,
                                input: v.value
                            })
                        }
                        name={ value }
                    />
                </div>
            )}
            {additionalElementType === 'select' && (
                <div className={ classNames(cnColQuarter, cnCenter) }>
                    <Select
                        className={ cnFullWidth }
                        title={ i18n[value].usage }
                        placeholder={ i18n[value].placeholderUsage }
                        controlledValue={ additionalValue }
                        value={ additionalValue }
                        disabled={ !selected }
                        options={ hourOptions }
                        onChange={ v =>
                            handleDemandSpecified(value, {
                                device: value,
                                additional: v.value
                            })
                        }
                        name={ value + '-select' }
                    />
                </div>
            )}
        </div>
    )
}