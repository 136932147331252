import React from 'react'

import { Headline } from '@varta/digital-styleguide/build/src/components/01.atoms'
import {
    CheckboxIcon,
    Form
} from '@varta/digital-styleguide/build/src/components/02.molecules'

import { useFormData } from '../../../redux/data/hooks/useFormData'
import { useNavigation } from '../../../redux/navigation/hooks/useNavigation'
import {
    TYPE_CARAVAN,
    TYPE_MOTORHOME,
    APPLICATION,
    VEHICLE_TYPE,
    APPLICATION_CAMPING
} from '../../../data/strings'

import './_index.scss'

const CLASSNAME = 'varta-leisure-camping-wizard'
const cnRow = `${ CLASSNAME }__row`
const cnCol = `${ CLASSNAME }__col`

/**
 * 
 * @returns 
 */
export default function VehicleType ({ i18n }) {

    const { formData, addInputFormData } = useFormData(APPLICATION_CAMPING)
    const {
        setNextVisibleForm,
        setConfirmButton,
        setTeaser,
        setWizardTeaserContent
    } = useNavigation(APPLICATION_CAMPING)

    /**
     * 
     * @param {event} e 
     */
    function handleChange (e) {
        addInputFormData(VEHICLE_TYPE, e.target.value)
        setConfirmButton(false)
        setTeaser(false)
        setWizardTeaserContent(null)
        setNextVisibleForm(VEHICLE_TYPE, APPLICATION)
    }

    return (
        <>
            <Headline level={ 2 }>
                { i18n[VEHICLE_TYPE].title }
            </Headline>
            <Form
                onChange={ handleChange }
            >
                <div className={ cnRow }>
                    <div className={ cnCol }>
                        <CheckboxIcon
                            customIcon={ '../assets/images/icon-mobile-home.svg' }
                            title={ i18n[VEHICLE_TYPE].typeMotorhome }
                            value={ TYPE_MOTORHOME }
                            selected={ formData[VEHICLE_TYPE] === TYPE_MOTORHOME }
                        />
                    </div>
                    <div className={ cnCol }>
                        <CheckboxIcon
                            customIcon={ '../assets/images/icon-caravan.svg' }
                            title={ i18n[VEHICLE_TYPE].typeCaravan }
                            value={ TYPE_CARAVAN }
                            selected={ formData[VEHICLE_TYPE] === TYPE_CARAVAN }
                        />
                    </div>
                </div>
            </Form>
        </>
    )
}