import React from 'react'

import LeisureNeedAnalyzer from './LeisureNeedAnalyzer';
import './app.scss'

/**
 * 
 * @returns 
 */
function App() {
  
  return (
    <div className='lna-app'>
      <LeisureNeedAnalyzer
        prefix=''
        showHeader={ true }
      />
    </div>
  )
}

export default App;
