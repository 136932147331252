import {
    ENERGY_FRIDGE,
    ENERGY_GPS,
    ENERGY_KETTLE,
    ENERGY_LIGHTS,
    ENERGY_MOBILE,
    ENERGY_RADIO,
    ENERGY_TABLET,
    ENERGY_TV,
    ENERGY_WATER_PUMP
} from '../data/strings'

/**
 * 
 * @returns 
 */
export const useCalculations = () => {

    /**
     * 
     * @param {object} demand 
     */
    function allDemandToPower (demand) {
        const consumerKeys = Object.keys(demand)
        const consumerValues = Object.values(demand)
        let power = 0
        for(let j = 0; j < consumerKeys.length; j++) {
            const consumer = consumerValues[j]
            if (consumer.valid && consumer.input) {
                const consumerPower = consumerToPower(consumerKeys[j], consumer.input, consumer.additional)
                power = power + consumerPower
            } else if (consumer.length > 0) {
                consumer.forEach(c => {
                    if (c.power) {
                        const consumerPower = consumerToPower(c.device, c.power, c.usage)
                        power = power + consumerPower
                    }
                })
            }
        }
        return power
    }

    /**
     * 
     * @param {string} consumer 
     * @param {int} input 
     * @param {int} additional 
     */
    function consumerToPower (consumer, input, additional) {
        const x = parseInt(input)
        const y = parseInt(additional)
        let power = 0
        switch (consumer) {
            case ENERGY_WATER_PUMP:
                power = 6.5 * x / 60
                break
            case ENERGY_KETTLE:
                power = 9.3 * x
                break
            case ENERGY_TV:
                power = 3 * x / 60
                break
            case ENERGY_FRIDGE:
                power = x / 12 * 24
                break
            case ENERGY_MOBILE:
                power = 3 * x
                break
            case ENERGY_TABLET:
                power = 10 * x
                break
            case ENERGY_GPS:
                power = 1.25 * x
                break
            case ENERGY_RADIO:
                power = 0.8 * x
                break
            case ENERGY_LIGHTS:
                power = 0.8 * x * y
                break
            default:
                power = x / 12 * y / 60
                break
        }

        return power
    }

    /**
     * 
     * @param {int} mca 
     * @returns 
     */
    function mcaToCca (mca) {
        return parseInt(mca) * 0.8
    }

    /**
     * 
     * @param {object} usage
     */
    function usageToPower (usage) {
        let power = 0
        let values = Object.values(usage)
        values.forEach(v => {
            if (v.valid && v.duration && v.power) {
                power = power + (v.duration * v.power / 60)
            }
        });

        return power
    }

    return  {
        allDemandToPower,
        consumerToPower,
        mcaToCca,
        usageToPower
    }
}