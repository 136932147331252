import React from 'react'
import { useHistory } from 'react-router'

import {
    ButtonPlain,
    Headline,
    Icon
} from '@varta/digital-styleguide/build/src/components/01.atoms'
import { LayoutGeneral } from '@varta/digital-styleguide/build/src/components/04.templates'

const
    CLASSNAME = 'varta-page__error',
    cnWrapper = `${ CLASSNAME }__wrapper`,
    cnTitle = `${ CLASSNAME }__title`,
    cnIcon = `${ CLASSNAME }__icon`,
    cnButton = `${ CLASSNAME }__button`

/**
 * 
 * @returns 
 */
export default function NotFoundView () {

    const history = useHistory()
    const title = 'Not Found'
    const text = 'The page you are looking for couldn\'t be found.'
    const button_Text = 'Homepage'

    return (
        <LayoutGeneral>
            <div className={ cnWrapper }>
                <Icon
                    id="cancel-light"
                    size="xl"
                    className={ cnIcon }
                />
                <Headline level={ 1 } className={ cnTitle }>{ title }</Headline>
                <p>{ text }</p>
                <ButtonPlain
                    className={ cnButton }
                    title={ button_Text }
                    onClick={ () => history.push('/')}
                    wide
                />
            </div>
        </LayoutGeneral>
    )
}