import React from 'react'

import {
    Checkbox,
    Headline
} from '@varta/digital-styleguide/build/src/components/01.atoms'
import { Form } from '@varta/digital-styleguide/build/src/components/02.molecules'

import { useFormData } from '../../../redux/data/hooks/useFormData'
import { useNavigation } from '../../../redux/navigation/hooks/useNavigation'
import {
    APP_DUAL_USE,
    APP_MOVER,
    APP_START,
    APP_SUPPLY,
    TYPE_CARAVAN,
    TYPE_MOTORHOME,
    BATTERY_SIZE,
    NIGHTS,
    APPLICATION,
    TECHNOLOGY_USED,
    VEHICLE_TYPE,
    APPLICATION_CAMPING,
    TEASER_VISIT_BATTERY_FINDER
} from '../../../data/strings'

/**
 * 
 * @returns 
 */
export default function Application ({ i18n }) {

    const { formData, addInputFormData } = useFormData(APPLICATION_CAMPING)
    const {
        setNextVisibleForm,
        setConfirmButton,
        setTeaser,
        setWizardTeaserContent
    } = useNavigation(APPLICATION_CAMPING)

    /**
     * 
     * @param {event} e 
     */
    function handleChange (e) {
        addInputFormData(APPLICATION, e.target.value)
        setConfirmButton(false)
        if (e.target.value === APP_START) {
            setTeaser(true)
            setWizardTeaserContent(TEASER_VISIT_BATTERY_FINDER)
            setNextVisibleForm(APPLICATION)
        } else {
            setTeaser(false)
            setWizardTeaserContent(null)
            let nextForm = TECHNOLOGY_USED
            if (e.target.value === APP_SUPPLY) {
                nextForm = NIGHTS
            } else if (e.target.value === APP_MOVER) {
                nextForm = BATTERY_SIZE
            }
            setNextVisibleForm(APPLICATION, nextForm)
        }
    }

    return (
        <>
            <Headline level={ 2 } >
                { i18n[APPLICATION].title }
            </Headline>
            <Form
                onChange={ handleChange }
            >
                { formData[VEHICLE_TYPE] === TYPE_MOTORHOME && (
                    <Checkbox
                        description={ i18n[APPLICATION].appStart }
                        type='radio'
                        value={ APP_START }
                        checked={ formData[APPLICATION] === APP_START }
                    />
                )}
                <Checkbox
                    description={ i18n[APPLICATION].appSupply }
                    type='radio'
                    value={ APP_SUPPLY }
                    checked={ formData[APPLICATION] === APP_SUPPLY }
                />
                { formData[VEHICLE_TYPE] === TYPE_MOTORHOME && (
                    <Checkbox
                        description={ i18n[APPLICATION].appDualUse }
                        type='radio'
                        value={ APP_DUAL_USE }
                        checked={ formData[APPLICATION] === APP_DUAL_USE }
                    />
                )}
                { formData[VEHICLE_TYPE] === TYPE_CARAVAN && (
                    <Checkbox
                        description={ i18n[APPLICATION].appMover }
                        type='radio'
                        value={ APP_MOVER }
                        checked={ formData[APPLICATION] === APP_MOVER }
                    />
                )}
            </Form>
        </>
    )
}