import React from 'react'
import { useParams } from 'react-router'
import classNames from 'classnames/dedupe'

import {
    HeaderSimple
} from '@varta/digital-styleguide/build/src/components/01.atoms'
import {
    CookieLayer,
    SideMenu
} from '@varta/digital-styleguide/build/src/components/02.molecules'
import { Footer } from '@varta/digital-styleguide/build/src/components/03.organisms'
import { LayoutGeneral } from '@varta/digital-styleguide/build/src/components/04.templates'

import { LanguageWidget } from '../LanguageWidget'
import { useSettings } from '../../redux/settings/hooks/useSettings'

import './_index.scss'

const CLASSNAME = 'varta-leisure-layout'
const cnHeaderWidget = `${ CLASSNAME }__header-widget`
const cnFooter = `${ CLASSNAME }__footer`
const cnPrintNoShow = 'print-no-show'

/**
 * 
 * @returns 
 */
export default function LayoutLeisure ({
    className,
    i18n,
    children,
    sideMenuItems,
    onSideMenuClick,
    languageLink
}) {

    const { locale } = useParams()
    const lang = locale && locale.length <= 5 ? locale : (navigator.language || process.env.REACT_APP_LOCALE)
    const languageWidget = renderLanguageWidget()

    const {
        cookiesAccepted,
        mobile,
        acceptCookies
    } = useSettings()

    /**
     * 
     * @returns 
     */
     function renderLanguageWidget (iconOnly) {
        let selected = lang.slice(0, 2) || locale.slice(0, 2)
        return (
            <LanguageWidget
                flag={ i18n.general[selected].locale }
                name={ i18n.general[selected].name }
                iconOnly={ iconOnly }
                modalTitle={ i18n.general.localeModalTitle }
                selectTitle={ i18n.general.localeModalContent }
                selectDefault={ locale }
                selectOptions={ [
                    { value: 'de-DE', label: i18n.general.de.name },
                    { value: 'da-DK', label: i18n.general.da.name },
                    { value: 'en-GB', label: i18n.general.en.name },
                    { value: 'fr-FR', label: i18n.general.fr.name },
                    { value: 'nl-NL', label: i18n.general.nl.name },
                    { value: 'sv-SE', label: i18n.general.sv.name },
                ] }
                selected={ selected }
                btnText={ i18n.general.btnSave }
                link={ languageLink }
            />
        )
    }

    return (
        <LayoutGeneral className={ classNames(CLASSNAME, className) }>
            <SideMenu
                open={ true }
                animated={ false }
                title={ i18n.frontpage.sidebarTitle }
                mobile={ mobile }
                locale={ locale || process.env.REACT_APP_LOCALE }
                labelHomepage={ i18n.frontpage.btnHome }
                bottomWidget={ languageWidget }
                homeLink=''
                items={ sideMenuItems }
                onClick={ onSideMenuClick }
            />
            <HeaderSimple className={ cnPrintNoShow }>
                <a
                    href={`/${ locale }`}
                    style={{ display: 'flex' }}
                >
                    <img
                        src={'../assets/images/varta.logo.svg'}
                        style={{ height: 25 }}
                    />
                </a>
                <div className={ cnHeaderWidget } >
                    { renderLanguageWidget(true) }
                </div>
            </HeaderSimple>
            { children }
            
            {/* <CookieLayer
                message={ i18n.general.cookies.content }
                headline={ i18n.general.cookies.title }
                onConfirm={ acceptCookies }
                i18n={ {
                    accept: i18n.general.cookies.btnConfirm,
                } }
            /> */}
            <div
                className={ classNames(cnFooter, cnPrintNoShow) }
            >
                <Footer
                    items={ [
                        { title: i18n.footer.terms.title, link: i18n.footer.terms.link },
                        { title: i18n.footer.privacy.title, link: i18n.footer.privacy.link },
                        { title: i18n.footer.imprint.title, link: i18n.footer.imprint.link }
                    ] }
                />
            </div>
        </LayoutGeneral>
    )
}