import React from 'react'

import {
    CheckboxSlider,
    Form,
    HeadlineInfo
} from '@varta/digital-styleguide/build/src/components/02.molecules'

import { useFormData } from '../../../redux/data/hooks/useFormData'
import { useNavigation } from '../../../redux/navigation/hooks/useNavigation'

import {
    APP_DUAL_USE,
    APP_MOVER,
    BATTERY_SIZE,
    ENERGY_DEMAND,
    APPLICATION,
    SIZE_EXTRA,
    SIZE_LARGE,
    SIZE_MEDIUM,
    SIZE_SMALL,
    SIZE_TINY,
    MANEUVERS,
    APPLICATION_CAMPING
} from '../../../data/strings'

/**
 * 
 * @returns 
 */
export default function BatterySize ({ i18n }) {

    const { formData, addInputFormData } = useFormData(APPLICATION_CAMPING)
    const { setNextVisibleForm, setConfirmButton } = useNavigation(APPLICATION_CAMPING)

    const sizeOptions = formData[APPLICATION] === APP_DUAL_USE ? [
        { label: '207 x 175 x 190 mm', value: SIZE_TINY },
        { label: '242 x 175 x 190 mm', value: SIZE_SMALL },
        { label: '278 x 175 x 190 mm', value: SIZE_MEDIUM },
        { label: '353 x 175 x 190 mm', value: SIZE_LARGE },
        { label: '394 x 175 x 190 mm', value: SIZE_EXTRA }
    ] : [
        { label: '207 x 175 x 190 mm', value: SIZE_TINY },
        { label: '242 x 175 x 190 mm', value: SIZE_SMALL },
        { label: '278 x 175 x 190 mm', value: SIZE_MEDIUM },
        { label: '353 x 175 x 190 mm', value: SIZE_LARGE }
    ]

    /**
     * 
     * @param {event} e 
     */
    function handleChange (e) {
        addInputFormData(BATTERY_SIZE, e.target.value)
        setConfirmButton(false)
        let nextForm = ENERGY_DEMAND
        if (formData[APPLICATION] === APP_MOVER) {
            nextForm = MANEUVERS
        }
        setNextVisibleForm(BATTERY_SIZE, nextForm)
    }

    return (
        <>
            <HeadlineInfo level={2} info={ i18n[BATTERY_SIZE].info }>
                { i18n[BATTERY_SIZE].title }
            </HeadlineInfo>
            <Form
                onChange={ handleChange }
            >
                <CheckboxSlider
                    iconId='battery'
                    options={ sizeOptions }
                    selected={ formData[BATTERY_SIZE] }
                />
            </Form>
        </>
    )
}