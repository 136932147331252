export const hourOptions = [
    { value: '0.5', label: '0.5' },
    { value: '1', label: '1' },
    { value: '1.5', label: '1.5' },
    { value: '2', label: '2' },
    { value: '2.5', label: '2.5' },
    { value: '3', label: '3' },
    { value: '3.5', label: '3.5' },
    { value: '4', label: '4' },
    { value: '4.5', label: '4.5' },
    { value: '5', label: '5' },
    { value: '5.5', label: '5.5' },
    { value: '6', label: '6' },
    { value: '6.5', label: '6.5' },
    { value: '7', label: '7' },
    { value: '7.5', label: '7.5' },
    { value: '8', label: '8' },
    { value: '8.5', label: '8.5' },
    { value: '9', label: '9' },
    { value: '9.5', label: '9.5' },
    { value: '10', label: '10' },
    { value: '10.5', label: '10.5' },
    { value: '11', label: '11' },
    { value: '11.5', label: '11.5' },
    { value: '12', label: '12' },
    { value: '12.5', label: '12.5' },
    { value: '13', label: '13' },
    { value: '13.5', label: '13.5' },
    { value: '14', label: '14' },
    { value: '14.5', label: '14.5' },
    { value: '15', label: '15' },
    { value: '15.5', label: '15.5' },
    { value: '16', label: '16' },
    { value: '16.5', label: '16.5' },
    { value: '17', label: '17' },
    { value: '17.5', label: '17.5' },
    { value: '18', label: '18' },
    { value: '18.5', label: '18.5' },
    { value: '19', label: '19' },
    { value: '19.5', label: '19.5' },
    { value: '20', label: '20' },
    { value: '20.5', label: '20.5' },
    { value: '21', label: '21' },
    { value: '21.5', label: '21.5' },
    { value: '22', label: '22' },
    { value: '22.5', label: '22.5' },
    { value: '23', label: '23' },
    { value: '23.5', label: '23.5' },
    { value: '24', label: '24' },
]