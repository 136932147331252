import { createActions, handleActions } from 'redux-actions'

const initState = {
    mobile: false,
    cookiesAccepted: false,
    loading: false
}

export const {
    setMobile,
    setCookiesAccepted,
    setLoadingState
} = createActions({
    SET_MOBILE: mobile => ({ mobile }),
    SET_COOKIES_ACCEPTED: cookiesAccepted => ({ cookiesAccepted }),
    SET_LOADING_STATE: loading => ({ loading })
})

export const reducer = handleActions(
    {
        [ setMobile ]: (state, { payload: { mobile }}) => ({
            ...state,
            mobile 
        }),
        [ setCookiesAccepted ]: (state, { payload: { cookiesAccepted }}) => ({
            ...state,
            cookiesAccepted 
        }),
        [ setLoadingState ]: (state, { payload: { loading }}) => ({
            ...state,
            loading
        })
    },
    initState
)