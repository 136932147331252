import React from 'react'

import {
    Checkbox,
    Headline
} from '@varta/digital-styleguide/build/src/components/01.atoms'
import { Form } from '@varta/digital-styleguide/build/src/components/02.molecules'

import { useFormData } from '../../../redux/data/hooks/useFormData'
import { useNavigation } from '../../../redux/navigation/hooks/useNavigation'
import {
    START_STOP_NO,
    START_STOP_YES,
    BATTERY_SIZE,
    NIGHTS,
    START_STOP,
    APPLICATION_CAMPING
} from '../../../data/strings'

/**
 * 
 * @returns 
 */
export default function StartStop ({ i18n }) {

    const { formData, addInputFormData } = useFormData(APPLICATION_CAMPING)
    const { setNextVisibleForm, setConfirmButton } = useNavigation(APPLICATION_CAMPING)

    /**
     * 
     * @param {event} e 
     */
    function handleChange (e) {
        addInputFormData(START_STOP, e.target.value)
        setConfirmButton(false)
        let nextForm = BATTERY_SIZE
        if (e.target.value === START_STOP_NO) {
                nextForm = NIGHTS
        }
        setNextVisibleForm(START_STOP, nextForm)
    }

    return (
        <>
            <Headline level={ 2 } >
                { i18n[START_STOP].title }
            </Headline>
            <Form
                onChange={ handleChange }
            >
                <Checkbox
                    description={ i18n[START_STOP].startStopYes }
                    type='radio'
                    value={ START_STOP_YES }
                    checked={ formData[START_STOP] === START_STOP_YES }
                />
                <Checkbox
                    description={ i18n[START_STOP].startStopNo }
                    type='radio'
                    value={ START_STOP_NO }
                    checked={ formData[START_STOP] === START_STOP_NO }
                />
            </Form>
        </>
    )
}