import React from 'react'

import { Headline } from '@varta/digital-styleguide/build/src/components/01.atoms'
import {
    CheckboxButton,
    Form    
} from '@varta/digital-styleguide/build/src/components/02.molecules'

import { useFormData } from '../../../redux/data/hooks/useFormData'
import { useNavigation } from '../../../redux/navigation/hooks/useNavigation'
import {
    APP_DUAL_USE,
    SOLAR_PANEL_NO,
    SOLAR_PANEL_YES,
    BATTERY_SIZE,
    SOLAR_PANEL,
    ENERGY_DEMAND,
    APPLICATION,
    APPLICATION_CAMPING
} from '../../../data/strings'

import './_index.scss'

const CLASSNAME = 'varta-leisure-camping-wizard'
const cnRow = `${ CLASSNAME }__row`
const cnCol = `${ CLASSNAME }__col`
const cnButtonFullWidth = `${ CLASSNAME }__button-full-width`

/**
 * 
 * @returns 
 */
export default function SolarPanel ({ i18n }) {

    const { formData, addInputFormData } = useFormData(APPLICATION_CAMPING)
    const { setNextVisibleForm, setConfirmButton } = useNavigation(APPLICATION_CAMPING)

    /**
     * 
     * @param {event} e 
     */
    function handleChange (e) {
        addInputFormData(SOLAR_PANEL, e.target.value)
        setConfirmButton(false)
        let nextForm = ENERGY_DEMAND
        if (formData[APPLICATION] === APP_DUAL_USE) {
            nextForm = BATTERY_SIZE
        }
        setNextVisibleForm(SOLAR_PANEL, nextForm)
    }

    return (
        <>
            <Headline level={ 2 }>
                { i18n[SOLAR_PANEL].title }
            </Headline>
            <Form
                onChange={ handleChange }
            >
                <div className={ cnRow }>
                    <div className={ cnCol }>
                        <CheckboxButton
                            className={ cnButtonFullWidth }
                            title={ i18n[SOLAR_PANEL].solarYes }
                            value={ SOLAR_PANEL_YES }
                            selected={ formData[SOLAR_PANEL] === SOLAR_PANEL_YES }
                        />
                    </div>
                    <div className={ cnCol }>
                        <CheckboxButton
                            className={ cnButtonFullWidth }
                            title={ i18n[SOLAR_PANEL].solarNo }
                            value={ SOLAR_PANEL_NO }
                            selected={ formData[SOLAR_PANEL] === SOLAR_PANEL_NO }
                        />
                    </div>
                </div>
            </Form>
        </>
    )
}