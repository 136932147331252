import da from './da.json'
import de from './de.json'
import en from './en.json'
import fr from './fr.json'
import nl from './nl.json'
import sv from './sv.json'

const locale = {
    'da': da,
    'de': de,
    'en': en,
    'fr': fr,
    'nl': nl,
    'sv': sv
}

export default locale
