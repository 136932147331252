import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

import { useCalculations } from '../../../utils/useCalculations'
import { useResult } from '../../result/hooks/useResult'
import { useSettings } from '../../settings/hooks/useSettings'
import { useNavigation } from '../../navigation/hooks/useNavigation'
import {
    APPLICATION_CAMPING,
    CCA_KEY,
    COLD_CA,
    ENERGY_DEMAND,
    MCA_KEY,
    USAGE
} from '../../../data/strings'

import wizardMarine from '../../../data/wizard-marine.json'
import wizardCamping from '../../../data/wizard-camping.json'

/**
 * 
 * @returns 
 */
export const useBatteryRecommendation = (application) => {
    const history = useHistory()
    const {
        allDemandToPower,
        mcaToCca,
        usageToPower
    } = useCalculations()
    const {
        setResultSummary,
        setResultNote,
        setResultRecommendation,
        setResultUpgrade
    } = useResult()
    const { setLoading } = useSettings()
    const { visibleForms } = useNavigation()
    const { formData } = useSelector(state => state.data[application])

    const wizard = application === APPLICATION_CAMPING ? wizardCamping : wizardMarine
    const baseURL = process.env.REACT_APP_LNA_SERVICE_ENDPOINT
    
    /**
     * fetch recommended battery from backend and set objects in result reducer
     */
    async function getBatteryRecommendation (locale) {
        setLoading(true)
        setResultSummary({
            activity: application,
            ...formData
        })
        const keys = Object.keys(formData)
        const values = Object.values(formData)
        let params = {}
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            const value = values[i]
            let pair = {}
            let power = 0
            if (key === COLD_CA) {
                const cca = Object.keys(value)[0] === CCA_KEY ?
                    parseInt(value[CCA_KEY]) :
                    mcaToCca(value[MCA_KEY])
                pair = cca ? {
                    cca
                } : {}
            } else if (key === USAGE) {
                power = usageToPower(value)
                pair = {
                    power
                }
            } else if (key === ENERGY_DEMAND) {
                power = allDemandToPower(value)
                pair = {
                    power
                }
            } else {
                pair = {
                    [wizard[key].parameter]: values[i]
                }
            }
            params = {
                ...params,
                ...pair
            }
        }

        try {
            const { status, data: response } = await axios({
                url: `${ baseURL }/${ locale }/${ application }`,
                method: 'get',
                params
            })
            setResultRecommendation(response.response.recommendation)
            setResultUpgrade(response.response.upgrade ?? null)
            setResultNote(response.response.note ?? null)
            setLoading(false)
            history.push(`/${ locale }/result`)
        } catch (err) {
            console.warn(err)
            setLoading(false)
        }
    }

    return {
        getBatteryRecommendation
    }
}