import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames/dedupe'

import {
    ButtonPlain,
    Divider,
    Headline,
    RichText,
    TeaserMini
} from '@varta/digital-styleguide/build/src/components/01.atoms'
import {
    Datasheet,
    Image,
    InfoBox,
    TableResponsive
} from '@varta/digital-styleguide/build/src/components/02.molecules'
import AnchorManager from '@varta/digital-styleguide/build/src/utils/anchorManager'
import ScrollAnimation from '@varta/digital-styleguide/build/src/utils/scrollAnimation'
import { useTracking } from '@varta/digital-styleguide/build/src/utils/useTracking'

import { LayoutLeisure } from '../components/LayoutLeisure'
import { useCustomTracking } from '../utils/useCustomTracking'
import { useLanguages } from '../utils/useLanguages'
import { useResult } from '../redux/result/hooks/useResult'

import data from '../data/locales'

import './_result.scss'



const CLASSNAME = 'varta-leisure-result'
const cnRow = `${ CLASSNAME }__row`
const cnCol = `${ CLASSNAME }__col`
const cnColData = `${ cnCol }--data`
const cnBatteries = `${ CLASSNAME }__batteries`
const cnBtnDownload = `${ CLASSNAME }__btnDownload`
const cnSpacer = `${ CLASSNAME }__spacer`
const cnSideMenu = `${ CLASSNAME }__side-menu`
const cnSideMenuGroup = `${ cnSideMenu }__group`
const cnSideMenuItem = `${ cnSideMenu }__item`
const cnSideMenuItemActive = `${ cnSideMenuItem }--active`
const cnPrintTableRow = 'print-table-row'
const cnPrintPageBreak = 'print-page-break'
const cnPrintNoShow = 'print-no-show'
const cnPrintNoBreak = 'print-no-break'

/**
 * 
 * @returns 
 */
export default function ResultView () {

    const { locale } = useParams()
    const history = useHistory()

    const { track } = useTracking('LNA-Event')
    const { trackSummary } = useCustomTracking()

    const { getLanguage } = useLanguages()
    const language = getLanguage(locale)
    const i18n = data[language.slice(0, 2)]

    const {
        summary,
        note,
        recommendation,
        upgrade,
        getSummaryTable
    } = useResult()
    const summaryTable = getSummaryTable(i18n.result.summary)

    // sidemenu anchors
    const anchorManager = useRef()
    const anchor1 = useRef(null)
    const anchor2 = useRef(null)
    const anchor3 = useRef(null)
    const anchors = [
        anchor1,
        anchor2,
        anchor3
    ]
    const [ activeSideMenuItem, setActiveSideMenuItem ] = useState('recommendation')

    // redirect to start if directly accessed
    useEffect(() => {
        if (!note && !recommendation && !upgrade) {
            history.push('/')
        } else {
            window.scrollTo(0, 0)
            // TODO send summary to datalayer
            trackSummary()
        }
        anchorManager.current = AnchorManager(anchors, id => {
            setActiveSideMenuItem(id)
        })
        anchorManager.current.mount()

        // fired on componentWillUnmount
        return () => {
            if (anchorManager.current) {
                anchorManager.current.unmount()
                anchorManager.current = null
            }
        }
    }, [])

    /**
     * 
     */
    function downloadBtnClicked () {
        track({
            'function': 'LNA-PDF'
        })
        window.print()
    }

    /**
     * navigate back to wizard
     */
    function backButtonClicked () {
        track({
            'function': 'LNA-back'
        })
        history.push(`/${ language }/${ summary.activity }#btnSend`)
    }

    /**
     *
     * @param {*} e
     * @param {*} parent
     * @param {*} item
     */
     function onSideMenuClick (e, parent, item) {
         console.log('side menu clicked', parent, item)
        if (parent === 'jump-to') {
            e.preventDefault()
            onSideMenuItemClick(e, item, `#${ item.id }`, item.offset ?? 0)
            return
        }
    }

    /**
     *
     * @param {*} e
     * @param {*} item
     * @param {*} scrollTarget
     * @param {*} offset
     */
    function onSideMenuItemClick (e, item, scrollTarget, offset) {
        e.preventDefault()
        e.stopPropagation()
        setActiveSideMenuItem(item.id)
        // dispatch(activateItem(item.id, 'top'))
        anchorManager.current.unmount()
        ScrollAnimation(scrollTarget, offset).then(() => {
            anchorManager.current.mount()
        })
    }

    /**
     * 
     * @param {object} battery 
     * @param {int} idx
     */
     function renderBattery (battery, idx) {
        if (!battery) {
            return null
        }
        return (
            <div className={ cnCol, cnColData } key={ battery.details.productline }>
                <Datasheet
                    className={ cnBatteries }
                    title={ battery.details.productline }
                    image={ battery.image }
                    tooltip={ !idx
                        ? i18n.result.datasheet.recommendation
                        : i18n.result.datasheet.upgrade
                    }
                    type={ !idx
                        ? 'recommendation'
                        : 'upgrade'
                    }
                    typeName={ !idx
                        ? i18n.result.datasheet.recommendation
                        : i18n.result.datasheet.upgrade
                    }
                    // typeTitle={ !idx
                    //     ? i18nTypes.Recommendation_Title?.value
                    //     : i18nTypes.Upselling_Title?.value
                    // }
                    // typeDescription={ !idx
                    //     ? i18nTypes.Recommendation_Description?.value
                    //     : i18nTypes.Upselling_Description?.value
                    // }
                    infoButtonTitle={
                        i18n.result.datasheet.infoButtonTitle
                    }
                    downloadButtonTitle={
                        i18n.result.datasheet.downloadButton
                    }
                    benefitsTitle={
                        i18n.result.datasheet.benefitsTitle
                    }
                    keybenefits={ battery.keybenefits }
                    productsheet={ battery.productsheet }
                    onClickDownload={ e => {
                        track({ 'function': 'LNA-DownloadDatasheet' });
                        window.location.href = e.currentTarget.href
                    } }
                    details={ [
                        [
                            [ i18n.result.datasheet.shortCode ],
                            [ battery.details.short_code ]
                        ],
                        [
                            [ i18n.result.datasheet.ukCode ],
                            [ battery.details.uk_no || '–' ]
                        ],
                        [
                            [ i18n.result.datasheet.size ],
                            [ `${ battery.details.length } / ${ battery.details.width } / ${ battery.details.height } mm` ]
                        ],
                        [
                            [ i18n.result.datasheet.voltage ],
                            [ `${ battery.details.voltage } V` ]
                        ],
                        [
                            [ i18n.result.datasheet.capacity ],
                            [ `${ battery.details.ah } Ah` ]
                        ],
                        [
                            [ i18n.result.datasheet.cca ],
                            [ `${ battery.details.cca } Ah` ]
                        ],
                    ] }
                />
            </div>
        )
    }

    return (
        <LayoutLeisure
            i18n={i18n}
            languageLink={ '/result' }
            onSideMenuClick={ onSideMenuClick }
            sideMenuItems={
                [
                    {
                        className: cnSideMenuGroup,
                        id: 'jump-to',
                        items: [{
                            title: i18n.result.sideAnchors.recommendation,
                            link: '/',
                            className: cnSideMenuItem,
                            classNameActive: cnSideMenuItemActive,
                            id: 'recommendation',
                            active: activeSideMenuItem === 'recommendation',
                            offset: 80
                        },
                        {
                            title: i18n.result.sideAnchors.download,
                            link: '/',
                            className: cnSideMenuItem,
                            classNameActive: cnSideMenuItemActive,
                            id: 'download',
                            active: activeSideMenuItem === 'download',
                            offset: 80
                        },
                        {
                            title: i18n.result.sideAnchors.summary,
                            link: '/',
                            className: cnSideMenuItem,
                            classNameActive: cnSideMenuItemActive,
                            id: 'summary',
                            active: activeSideMenuItem === 'summary',
                            offset: 80
                        }]
                    }
                ]
            }
        >
            <div id='header'>
                <Image
                    srcset={ {
                        xs: '../assets/images/logo-result.png',
                        sm: '../assets/images/logo-result.png',
                        md: '../assets/images/logo-result.png',
                        xl: '../assets/images/logo-result.png'
                    } }
                    alt='VARTA Leisure Need Analyzer Header Image'
                />
            </div>
            <div
                id='recommendation'
                ref={ anchors[0] }
            />
            <Headline level={ 1 }>
                { i18n.result.title }
            </Headline>
            <div className={ cnPrintNoBreak }>
                <Headline level={ 2 }>
                    { i18n.result.recommendationHeadline }
                </Headline>
                <div className={ cnSpacer } />
                <RichText
                    content={ i18n.result.recommendation }
                />
                <div className={ cnSpacer } />
                { upgrade && (
                    <RichText
                        content={ i18n.result.upgrade }
                    />
                ) }
            </div>
            { note && (
                <>
                    <div className={ cnSpacer }/>
                    <div className={ cnPrintNoBreak }>
                        <InfoBox
                            type='warning'
                            title={ i18n.result[note].title }
                            content={ i18n.result[note].content }
                            iconId='warning'
                        />
                    </div>
                </>
            ) }
            <div className={ cnSpacer } />
            <div className={ classNames(cnRow, cnPrintPageBreak) }>
                { recommendation && renderBattery(recommendation) }
                { upgrade && renderBattery(upgrade, 1) }
            </div>
            <div
                id='download'
                ref={ anchors[1] }
                className={ cnPrintNoShow }
            >
                <Headline level={ 2 }>
                    { i18n.result.downloadHeadline }
                </Headline>
                <RichText
                    content={ i18n.result.downloadContent }
                />
                <div className={ cnRow }>
                    <div className={ cnCol }>
                        <TeaserMini
                            title={ i18n.result.btnDownload }
                            subline={ i18n.result.btnDownloadSubline }
                            onClick={ downloadBtnClicked }
                            iconId='download'
                            className={ cnBtnDownload }
                        />
                    </div>
                </div>
                <Divider />
                <Headline level={ 2 }>
                    { i18n.result.changeConfigHeadline }
                </Headline>
                <RichText
                    content={ i18n.result.changeConfigContent }
                />
                <ButtonPlain
                    title={ i18n.result.btnBack }
                    onClick={ backButtonClicked }
                />
                <div className={ cnSpacer } />
                <Divider />
            </div>
            <div
                id='summary'
                ref={ anchors[2] }
                className={ cnPrintNoBreak }
            >
                <Headline level={ 2 }>
                    { i18n.result.summary.title }
                </Headline>
                <TableResponsive
                    classNameRow={ cnPrintTableRow }
                    title='summary'
                    items={ summaryTable }
                />
            </div>
            <div className={ cnPrintNoShow }>
                <RichText
                    content={ i18n.result.footnotes }
                />
            </div>
        </LayoutLeisure>
    )
}