import { useDispatch, useSelector } from 'react-redux'

import { useCalculations } from '../../../utils/useCalculations'
import {
    setNote,
    setRecommendation,
    setSummary,
    setUpgrade
} from '../resultReducer'
import {
    COLD_CA,
    ENERGY_DEMAND,
    ENERGY_FRIDGE,
    ENERGY_GPS,
    ENERGY_KETTLE,
    ENERGY_LIGHTS,
    ENERGY_MOBILE,
    ENERGY_RADIO,
    ENERGY_TABLET,
    USAGE,
} from '../../../data/strings'

/**
 * 
 * @returns 
 */
export const useResult = () => {
    const dispatch = useDispatch()

    const { mcaToCca } = useCalculations()
    const { summary, note, recommendation, upgrade } = useSelector(state => state.result)

    /**
     * 
     * @param {object} summary 
     */
     function getSummaryTable (i18n) {
        let summaryTable = []
        for(let i = 0; i < Object.keys(summary).length; i++) {
            const key = Object.keys(summary)[i]
            const value = Object.values(summary)[i]
            let row = [ [ i18n[key].title ], [ <b>{ i18n[key][value] }</b> ] ]

            if (key === COLD_CA) {
                row = [ [ i18n[key].title ], [ <b>{ (value.cca ?? mcaToCca(value.mca)) + ' ' + i18n[COLD_CA].unit }</b> ] ]
            } 

            if (key === USAGE) {
                const usageKeys = Object.keys(value)
                let consumerCell = []
                const translation = i18n[USAGE]
                usageKeys.forEach(usageKey => {
                    if (value[usageKey].valid && value[usageKey].duration) {
                        consumerCell = <>{ consumerCell }<b>{ translation[usageKey]  + ' (' + translation.for + ' ' + (value[usageKey].duration ?? 0) + ' ' + translation.minutes + ')'}</b><br/></>
                    }
                })
                if (!Array.isArray(consumerCell)) {
                    row = [ [ i18n[key].title ], consumerCell ]
                } else {
                    row = []
                }
            }

            if (key === ENERGY_DEMAND) {
                const consumers = Object.values(value)
                let consumerCell = []
                consumers.forEach(consumer => {
                    const translation = i18n[ENERGY_DEMAND]
                    const device = consumer.device
                    if (consumer.valid) {
                        if (device === ENERGY_LIGHTS && consumer.input) {
                            if (consumer.additional) {
                                consumerCell = <>{ consumerCell }<b>{consumer.input + ' ' + translation[device] + ' (' + consumer.additional + ' ' + translation['hoursPerDay'] + ')' }</b><br/></>
                            }
                        } else if ((device === ENERGY_MOBILE || device === ENERGY_TABLET) && consumer.input) {
                            consumerCell =<>{ consumerCell }<b>{ consumer.input + ' ' + translation[device] + ' ' + translation.charging }</b><br/></>
                        } else if (consumer.input) {
                            let unit = 'minutesPerDay'
                            if (device === ENERGY_KETTLE) {
                                unit = 'timesPerDay'
                            } else if (device === ENERGY_FRIDGE) {
                                unit = 'unit'
                            } else if (device === ENERGY_GPS || device === ENERGY_RADIO) {
                                unit = 'hoursPerDay'
                            }
                            consumerCell = <>{ consumerCell }<b>{ translation[device] + ' (' + consumer.input + ' ' + translation[unit] + ')' }</b><br/></>
                        }
                    } else if (Array.isArray(consumer)) {
                        consumer.forEach(additional => {
                            if (additional.power && additional.usage) {
                                consumerCell = <>{ consumerCell }<b>{ additional.device + ' (' + additional.power + ' ' + translation['unit'] + ', ' + additional.usage + ' ' + translation['minutesPerDay'] + ')'}</b><br/></>
                            }
                        })
                    }
                })
                if (consumerCell.length === 0) {
                    row = [ [ i18n[key].title ], <b>{ i18n[ENERGY_DEMAND].none }</b> ]
                } else {
                    row = [ [ i18n[key].title ], consumerCell ]
                }
            }

            summaryTable.push(row)
        }

        return summaryTable
    }

    /**
     * 
     * @param {array} summary 
     */
    function setResultSummary (summary) {
        dispatch(setSummary(summary))
    }

    /**
     * 
     * @param {string} note 
     */
    function setResultNote (note) {
        dispatch(setNote(note))
    }

    /**
     * 
     * @param {object} recommendation 
     */
    function setResultRecommendation (recommendation) {
        dispatch(setRecommendation(recommendation))
    }

    /**
     * 
     * @param {upgrade} upgrade 
     */
    function setResultUpgrade (upgrade) {
        dispatch(setUpgrade(upgrade))
    }

    /**
     * reset result reducer to initState
     */
    function clearResult () {
        setResultSummary({})
        setResultNote(null)
        setResultRecommendation(null)
        setResultUpgrade(null)
    }

    return {
        summary,
        note,
        recommendation,
        upgrade,
        getSummaryTable,
        setResultSummary,
        setResultNote,
        setResultRecommendation,
        setResultUpgrade,
        clearResult
    }
}