import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames/dedupe'

import {
    ButtonPlain,
    Headline
} from '@varta/digital-styleguide/build/src/components/01.atoms'
import {
    Image
} from '@varta/digital-styleguide/build/src/components/02.molecules'
import { useMediaQueries } from '@varta/digital-styleguide/build/src/utils/useMediaQueries'

import { LayoutLeisure } from '../components/LayoutLeisure'
import { useFormData } from '../redux/data/hooks/useFormData'
import { useLanguages } from '../utils/useLanguages'
import { useNavigation } from '../redux/navigation/hooks/useNavigation'
import { useResult } from '../redux/result/hooks/useResult'
import { useSettings } from '../redux/settings/hooks/useSettings'

import { APPLICATION_CAMPING, APPLICATION_MARINE } from '../data/strings'
import data from '../data/locales'

import './_index.scss'

const CLASSNAME = 'varta-leisure-frontpage'
const cnRow = `${ CLASSNAME }__row`
const cnCol = `${ CLASSNAME }__col`
const cnActivity = `${ CLASSNAME }__activity`
const cnActivityButton = `${ cnActivity }--button`

/**
 * 
 * @returns 
 */
export default function StartView () {
    const history = useHistory()
    
    const { locale } = useParams()
    const { getLanguage } = useLanguages()
    const language = getLanguage(locale)
    const i18n = data[language.slice(0, 2)]

    const { observeMediaQueries } = useMediaQueries()
    const { clearInputFormData } = useFormData()
    const {
        clearVisibleForms,
        setConfirmButton
    } = useNavigation()
    const { clearResult } = useResult()
    const { setMedia } = useSettings()
    const {
        setTeaser,
        setWizardTeaserContent
    } = useNavigation()

    // init media observer
    useEffect(() => {
        observeMediaQueries(function (bp, mo) {
            setMedia(bp, mo)
        })
    }, [])

    useEffect(() => {
        history.push(`/${ language }`)
    }, [ language ])

    /**
     * 
     * @param {string} application 
     */
    function startWizardClicked (application) {
        setConfirmButton(false, application)
        clearInputFormData(application)
        clearVisibleForms(application)
        setTeaser(false, application)
        setWizardTeaserContent(null, application)
        clearResult()
        history.push(`${ language }/${ application }`)
    }

    return (
        <LayoutLeisure
            i18n={i18n}
            languageLink={ '' }
        >
            <Headline level={ 1 }>
                { i18n.frontpage.title }
            </Headline>
            <Headline level={ 3 }>
                { i18n.frontpage.content }
            </Headline>
            <div className={ cnRow }>
                <div className={ classNames(cnCol, cnActivity) }>
                    <Image
                        srcset={ {
                            xs: '../assets/images/logo-camping.jpg',
                            sm: '../assets/images/logo-camping.jpg',
                            md: '../assets/images/logo-camping.jpg',
                            xl: '../assets/images/logo-camping.jpg'
                        } }
                        alt='Caravan'
                    />
                    <ButtonPlain
                        className={ cnActivityButton }
                        title={ i18n.frontpage.btnCamping }
                        onClick={ () => startWizardClicked(APPLICATION_CAMPING) }
                    />
                </div>
                <div className={ classNames(cnCol, cnActivity) }>
                    <Image
                        srcset={ {
                            xs: '../assets/images/logo-marine.jpg',
                            sm: '../assets/images/logo-marine.jpg',
                            md: '../assets/images/logo-marine.jpg',
                            xl: '../assets/images/logo-marine.jpg'
                        } }
                        alt='Sailboat'
                    />
                    <ButtonPlain
                        className={ cnActivityButton }
                        title={ i18n.frontpage.btnMarine }
                        onClick={ () => startWizardClicked(APPLICATION_MARINE) }
                    />
                </div>
            </div>
        </LayoutLeisure>
    )
}