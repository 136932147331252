import { createActions, handleActions } from 'redux-actions'

const initState = {
    summary: {},
    note: null,
    recommendation: null,
    upgrade: null
}

export const {
    setSummary,
    setNote,
    setRecommendation,
    setUpgrade,
} = createActions({
    SET_SUMMARY: summary => ({ summary }),
    SET_NOTE: note => ({ note }),
    SET_RECOMMENDATION: recommendation => ({ recommendation }),
    SET_UPGRADE: upgrade => ({ upgrade }),
})

export const reducer = handleActions(
    {
        [ setSummary ]: (state, { payload: { summary }}) => ({
            ...state,
            summary
        }),
        [ setNote ]: (state, { payload: { note }}) => ({
            ...state,
            note
        }),
        [ setRecommendation ]: (state, { payload: { recommendation }}) => ({
            ...state,
            recommendation
        }),
        [ setUpgrade ]: (state, { payload: { upgrade } }) => ({
            ...state,
            upgrade
        })
    },
    initState
)