import { useDispatch, useSelector } from 'react-redux'
import { setCookiesAccepted, setLoadingState, setMobile } from '../settingsReducer'

/**
 * 
 * @returns 
 */
export const useSettings = () => {
    const dispatch = useDispatch()

    const { mobile, cookiesAccepted, loading } = useSelector(state => state.settings)

    /**
     * 
     * @param {string} mediaBreakpoint 
     * @param {bool} mobile 
     */
    function setMedia (mediaBreakpoint, mobile) {
        dispatch(setMobile(mobile))
    }

    /**
     * accept cookies
     */
    function acceptCookies () {
        dispatch(setCookiesAccepted(true))
    }

    /**
     * 
     * @param {bool} loading 
     */
    function setLoading (loading) {
        dispatch(setLoadingState(loading))
    }

    return {
        mobile,
        cookiesAccepted,
        loading,
        setMedia,
        acceptCookies,
        setLoading
    }
}