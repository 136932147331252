import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import {
    ButtonPlain,
    LoaderContent,
} from '@varta/digital-styleguide/build/src/components/01.atoms'
import {
    BreadCrumb,
    Form,
    Image,
    TextImageCTA,
} from '@varta/digital-styleguide/build/src/components/02.molecules'

import AnchorManager from '@varta/digital-styleguide/build/src/utils/anchorManager'
import ScrollAnimation from '@varta/digital-styleguide/build/src/utils/scrollAnimation'

import Application from './Forms/Application'
import BatterySize from './Forms/BatterySize'
import EnergyDemand from './Forms/EnergyDemand'
import Nights from './Forms/Nights'
import SolarPanel from './Forms/SolarPanel'
import StartStop from './Forms/StartStop'
import TechnologyUsed from './Forms/TechnologyUsed'
import Maneuvers from './Forms/Maneuvers'
import VehicleType from './Forms/VehicleType'
import { LayoutLeisure } from '../../components/LayoutLeisure'
import { useBatteryRecommendation } from '../../redux/data/hooks/useBatteryRecommendation'
import { useFormData } from '../../redux/data/hooks/useFormData'
import { useLanguages } from '../../utils/useLanguages'
import { useNavigation } from '../../redux/navigation/hooks/useNavigation'
import { useSettings } from '../../redux/settings/hooks/useSettings'

import { APPLICATION_CAMPING } from '../../data/strings'
import data from '../../data/locales'
import wizard from '../../data/wizard-camping.json'

import '../_wizards.scss'

const forms = {
    Application,
    BatterySize,
    EnergyDemand,
    Nights,
    SolarPanel,
    StartStop,
    TechnologyUsed,
    Maneuvers,
    VehicleType
}

const CLASSNAME = 'varta-leisure-wizard'
const cnLayout = `${ CLASSNAME }__layout`
const cnHeaderImage = `${ CLASSNAME }__header-image`
const cnConfirmButtonWrapper = `${ CLASSNAME }__confirm-button-wrapper`
const cnConfirmButton = `${ CLASSNAME }__confirm-button`
const cnFormWrapper = `${ CLASSNAME }__form-wrapper`
const cnTeaser = `${ CLASSNAME }__teaser`
const cnSpacer = `${ CLASSNAME }__spacer`

/**
 * 
 * @returns 
 */
export default function WizardCamping () {
    const history = useHistory()

    const { locale } = useParams()
    const { getLanguage } = useLanguages()
    const language = getLanguage(locale)
    const i18n = data[language.slice(0, 2)]

    const { getBatteryRecommendation } = useBatteryRecommendation(APPLICATION_CAMPING)
    const { formData } = useFormData(APPLICATION_CAMPING)
    const {
        visibleForms,
        showConfirmButton,
        showTeaser,
        teaserContent,
    } = useNavigation(APPLICATION_CAMPING)
    const { mobile, loading } = useSettings()

    const [ activeForm, setActiveForm ] = useState('')
    const anchorManager = useRef()

    const anchor1 = useRef(null)
    const anchor2 = useRef(null)
    const anchor3 = useRef(null)
    const anchor4 = useRef(null)
    const anchor5 = useRef(null)
    const anchor6 = useRef(null)
    const anchor7 = useRef(null)
    const anchor8 = useRef(null)
    const anchor9 = useRef(null)
    const anchor10 = useRef(null)

    const anchors = [
        anchor1,
        anchor2,
        anchor3,
        anchor4,
        anchor5,
        anchor6,
        anchor7,
        anchor8,
        anchor9,
        anchor10
    ]

    useEffect(() => {
        history.push(`/${ language }/camping`)
    }, [ language ])

    // mount and unmount anchor manager for scrolling
    useEffect(() => {
        anchorManager.current = AnchorManager(anchors, id => {
            setActiveForm(id)
        })
        anchorManager.current.mount()

        return () => {
            if (anchorManager.current) {
                anchorManager.current.unmount()
                anchorManager.current = null
            }
        }
    }, [ anchorManager.current ])
   
    // scroll on visibleForms change
    useEffect(() => {
        const item = wizard[visibleForms[visibleForms.length - 1]]
        if (anchorManager?.current && item) {
            anchorManager.current.unmount()
            if (visibleForms.length === 1) {
                ScrollAnimation('#header', 0).then(() => {
                    anchorManager.current.mount()
                })        
            } else {
                ScrollAnimation(`#${ item.id }`, item.offset).then(() => {
                    anchorManager.current.mount()
                })
            }
        }
    }, [ visibleForms ])

    // scroll to teaser or send button when they enter
    useEffect(() => {
        if (showTeaser) {
            ScrollAnimation('#teaser', 20).then(() => {
                anchorManager.current.mount()
            })
        }
    }, [ showTeaser, showConfirmButton ])    

    /**
     * 
     * @param {*} e
     * @param {*} item
     * @param {*} scrollTarget
     * @param {*} offset
     */
    function breadCrumbClicked (e, item, scrollTarget, offset) {
        e.preventDefault()
        e.stopPropagation()
        anchorManager.current.unmount()
        ScrollAnimation(scrollTarget, offset).then(() => {
            anchorManager.current.mount()
        })
    }

    return (
        <LayoutLeisure
            i18n={ i18n }
            languageLink={ '/camping' }
            sideMenuItems={
                [ {
                    icon: 'search-alt',
                    id: 'breadcrumb',
                    widget: <BreadCrumb
                        activeStep={ activeForm !== 'top' ? activeForm : 'vehicle-type' }
                        items={
                            visibleForms
                                .map(a => ({
                                    title: i18n.forms[a]?.sidebarTitle,
                                    key: i18n.forms[a]?.sidebarTitle,
                                    id: wizard[a].id,
                                    ...a,
                                    valid: (activeForm !== wizard[a].id && visibleForms.length !== 1 && !!formData[a]) || showTeaser,
                                    onClick: e => breadCrumbClicked(e, wizard[a], `#${ wizard[a].id }`, wizard[a].offset ?? 0)
                                }))
                        }
                    />
                } ]
            }
        >
            <div className={ cnLayout }>
                { loading && (
                    <LoaderContent
                        fullscreen={ true }
                        delay={ 0 }
                    />
                )}
                <div id="header">
                    <Image
                        className={ cnHeaderImage }
                        srcset={ {
                            xs: '../assets/images/logo-camping.jpg',
                            sm: '../assets/images/logo-camping.jpg',
                            md: '../assets/images/logo-camping.jpg',
                            xl: '../assets/images/logo-camping.jpg'
                        } }
                        alt="Caravan header"
                    />
                </div>
                <Form
                    onSubmitSuccess={ () => getBatteryRecommendation(language) }
                >
                    { visibleForms.map(function (formName, idx) {
                        const CampingForm = forms[formName]
                        return (
                            <div
                                className={ cnFormWrapper }
                                key={ formName }
                                id={ wizard[formName].id }
                                ref={ anchors[idx] }
                            >
                                <CampingForm
                                    i18n={ i18n.forms }
                                />
                                <div className={ cnSpacer } />
                            </div>
                        )
                    })}
                    { showTeaser && (
                        <div id="teaser" style={{ backgroundColor: '#f2f4f5' }}>
                            <TextImageCTA
                                className={ cnTeaser }
                                headline={ i18n.general[teaserContent].title }
                                text={ i18n.general[teaserContent].content }
                                overlay={ !mobile }
                                style='dark'
                                cta
                                srcset={ {
                                    xs: '../assets/images/teaser-background.png',
                                    sm: '../assets/images/teaser-background.png',
                                    md: '../assets/images/teaser-background.png',
                                    xl: '../assets/images/teaser-background.png'
                                } }
                                title={ i18n.general[teaserContent].button }
                                link={ i18n.general[teaserContent].link }
                                target='_blank'
                            />
                        </div>
                    ) }
                    { showConfirmButton && (
                        <div id="btnSend" className={ cnConfirmButtonWrapper }>
                            <ButtonPlain
                                submit
                                title={ i18n.general.btnShowRecommendation }
                                className={ cnConfirmButton }
                            />
                        </div>
                    )}
                </Form>
            </div>
        </LayoutLeisure>
    )
}