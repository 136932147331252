import { createActions, handleActions } from 'redux-actions'

import { VEHICLE_TYPE } from '../../data/strings'

const initState = {
    camping: {
        visibleForms: [
            VEHICLE_TYPE
        ],
        showConfirmButton: false,
        showTeaser: false,
        teaserContent: null
    },
    marine: {
        visibleForms: [
            VEHICLE_TYPE
        ],
        showConfirmButton: false,
        showTeaser: false,
        teaserContent: null
    }
}

export const {
    setVisibleForms,
    setShowConfirmButton,
    setShowTeaser,
    setTeaserContent
} = createActions({
    SET_VISIBLE_FORMS: (application, visibleForms) => ({ application, visibleForms }),
    SET_SHOW_CONFIRM_BUTTON: (application, showConfirmButton) => ({ application, showConfirmButton }),
    SET_SHOW_TEASER: (application, showTeaser) => ({ application, showTeaser }),
    SET_TEASER_CONTENT: (application, teaserContent) => ({ application, teaserContent })
})

export const reducer = handleActions(
    {
        [ setVisibleForms ]: (state, { payload: { application, visibleForms } }) => ({
            ...state,
            [application]: {
                ...state[application],
                visibleForms
            }
        }),
        [ setShowConfirmButton ]: (state, { payload: { application, showConfirmButton }}) => ({
            ...state,
            [application]: {
                ...state[application],
                showConfirmButton
            }
        }),
        [ setShowTeaser ]: (state, { payload: { application, showTeaser }}) => ({
            ...state,
            [application]: {
                ...state[application],
                showTeaser
            }
        }),
        [ setTeaserContent ]: (state, { payload: { application, teaserContent }}) => ({
            ...state,
            [application]: {
                ...state[application],
                teaserContent
            }
        }),
    },
    initState
)