import React from 'react'
import classNames from 'classnames/dedupe'

import {
    ButtonLink,
    Divider
} from '@varta/digital-styleguide/build/src/components/01.atoms'
import { Input } from '@varta/digital-styleguide/build/src/components/02.molecules'

import './_index.scss'

const CLASSNAME = 'varta-leisure-additional-consumer'
const cnRow = `${ CLASSNAME }__row`
const cnCol = `${ CLASSNAME }__col`
const cnColQuarter = `${ cnCol } ${ cnCol }--quarter`
const cnClose = `${ CLASSNAME }__close`

/**
 * 
 * @returns 
 */
export default function AdditionalConsumer ({
    i18n,
    demand,
    deletable,
    onDeleteClicked,
    handleChange
}) {

    return (
        <>
            <div className={ classNames(cnRow, cnClose) }>
                <div className={ cnCol }>
                    <Input
                        title={ i18n.type }
                        placeholder={ i18n.placeholderType }
                        onChange={ value => handleChange({ key: 'device', value }) }
                        name='device'
                        controlledValue={ demand.device }
                    />
                </div>
                <div className={ cnColQuarter }>
                    <Input
                        title={ i18n.current }
                        placeholder={ i18n.placeholderCurrent }
                        onChange={ value => handleChange({ key: 'power', value }) }
                        name='power'
                        type='number'
                        controlledValue={ demand.power }
                    />
                </div>
                <div className={ cnColQuarter }>
                    <Input
                        title={ i18n.usage }
                        placeholder={ i18n.placeholderUsage }
                        onChange={ value => handleChange({ key: 'usage', value }) }
                        name='usage'
                        type='number'
                        controlledValue={ demand.usage }
                    />
                </div>
            </div>
            { deletable && (
                <>
                    <ButtonLink
                        title={ i18n.btnDelete }
                        type="secondary"
                        iconLeft="times-small"
                        onClick={ onDeleteClicked }
                    />
                    <Divider />
                </>
            )}
        </>
    )
}