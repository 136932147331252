import { combineReducers } from 'redux'

import { reducer as data } from './data/dataReducer'
import { reducer as navigation } from './navigation/navigationReducer'
import { reducer as result } from './result/resultReducer'
import { reducer as settings } from './settings/settingsReducer'

export default combineReducers({
    data,
    navigation,
    result,
    settings
})
