import React, { useState } from 'react'
import { useHistory } from 'react-router'

import {
    ButtonLink,
    ButtonPlain,
    Icon,
} from '@varta/digital-styleguide/build/src/components/01.atoms'
import {
    Modal,
    SelectAdvanced
} from '@varta/digital-styleguide/build/src/components/02.molecules'

import './_index.scss'

const CLASSNAME = 'varta-leisure-language-widget'
const cnButton = `${ CLASSNAME }__button`
const cnGlobe = `${ CLASSNAME }__globe`

/**
 * 
 * @returns 
 */
export default function LanguageWidget ({
    flag,
    name,
    modalTitle,
    selectTitle,
    selectDefault,
    selectOptions,
    selected,
    btnText,
    link,
    iconOnly
}) {
    const history = useHistory()
    const [ languageModalOpen, setLanguageModalOpen ] = useState(false)

    let selec = selected

    console.log('LANG', selec)

    return (
        <>
            <div
                className={ CLASSNAME }
                onClick={ () => setLanguageModalOpen(true) }
            >
                { !iconOnly && (
                    <>
                        <Icon id={ `flag-${ flag }` } />
                        <div>
                            { name }
                        </div>
                    </>
                )}
                { iconOnly && (
                    <>
                        <ButtonLink
                            className={ cnGlobe }
                            iconId='web'
                            iconSize='sm'
                            type='secondary'
                        />
                    </>
                )}
            </div>
            <Modal
                open={ languageModalOpen }
                onClose={ () => setLanguageModalOpen(false) }
                showCloseButton={ true }
                title={ modalTitle }
            >
                <SelectAdvanced
                    title={ selectTitle }
                    defaultValue={ selectDefault }
                    options={ selectOptions }
                    onChange={ value => selec = value.value }
                />
                <div className={ cnButton }>
                    <ButtonPlain
                        title={ btnText }
                        onClick={ () => {
                            setLanguageModalOpen(false)
                            history.push( `/${ selec }${ link }` )
                        }}
                    />
                </div>
            </Modal>
        </>
    )
}