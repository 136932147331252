import React, { useEffect, useState } from 'react'
import classNames from 'classnames/dedupe'

import {
    ButtonPlain,
    Checkbox
} from '@varta/digital-styleguide/build/src/components/01.atoms'
import {
    Form,
    HeadlineInfo,
    Input
} from '@varta/digital-styleguide/build/src/components/02.molecules'

import { useFormData } from '../../../redux/data/hooks/useFormData'
import { useNavigation } from '../../../redux/navigation/hooks/useNavigation'
import {
    APPLICATION,
    APPLICATION_MARINE,
    APP_DUAL_USE,
    CCA_KEY,
    COLD_CA,
    MCA_KEY,
    NIGHTS,
    TEASER_CHECK_INPUT,
    TEASER_CONTACT_MANUFACTURER
} from '../../../data/strings'

import './_index.scss'
import { useCalculations } from '../../../utils/useCalculations'

const CLASSNAME = 'varta-leisure-marine-wizard'
const cnRow = `${ CLASSNAME }__row`
const cnCol = `${ CLASSNAME }__col`
const cnColQuarter = `${ cnCol } ${ cnCol }--quarter`
const cnCcaButton = `${ CLASSNAME }__cca-button`

/**
 * 
 * @returns 
 */
export default function CCA ({ i18n }) {

    const { formData, addInputFormData } = useFormData(APPLICATION_MARINE)
    const {
        setNextVisibleForm,
        setConfirmButton,
        setTeaser,
        setWizardTeaserContent
    } = useNavigation(APPLICATION_MARINE)
    const { mcaToCca } = useCalculations()

    const [ cca, setCca ] = useState(formData[COLD_CA]?.cca)
    const [ mca, setMca ] = useState(formData[COLD_CA]?.mca)
    const [ onlyMCA, setOnlyMCA ] = useState(false)
    const [ none, setNone ] = useState(false)

    // observe 'none' checkbox changes
    useEffect(() => {
        setConfirmButton(false)
        setTeaser(none)
        setWizardTeaserContent(none ? TEASER_CONTACT_MANUFACTURER : null)
    }, [ none ])

    // observe 'onlyMCA' checkbox changes
    useEffect(() => {
        setConfirmButton(false)
        if (!onlyMCA && cca) {
            addInputFormData(COLD_CA, { cca })
            setConfirmButton(true)
        }
    }, [ onlyMCA ])

    /**
     * 
     */
    function onConfirmInput (key) {
        setConfirmButton(false)
        const value = key === CCA_KEY ? parseInt(cca) : mcaToCca(mca)
        if (formData[APPLICATION] === APP_DUAL_USE) {
            if (value > 1200) {
                setTeaser(true)
                setWizardTeaserContent(TEASER_CHECK_INPUT)
            } else {
                setTeaser(false)
                setWizardTeaserContent(null)
                setNextVisibleForm(COLD_CA, NIGHTS)
            }
        } else {
            if (value > 800) {
                setTeaser(true)
                setWizardTeaserContent(TEASER_CHECK_INPUT)
            } else {
                setTeaser(false)
                setWizardTeaserContent(null)
                addInputFormData(COLD_CA, { [key]: key === CCA_KEY ? cca : mca })
                setConfirmButton(true)
            }
        }
    }

    return (
        <>
            <HeadlineInfo level={2} info={ i18n[COLD_CA].info }>
                { i18n[COLD_CA].title }
            </HeadlineInfo>
            <div className={ cnRow }>
                <div className={ cnCol }>
                    <Input
                        title={ i18n[COLD_CA].ccaInput }
                        placeholder={ i18n[COLD_CA].inputPlaceholder }
                        value={ cca }
                        onChange={ v => setCca(v) }
                        type='number'
                        disabled={ onlyMCA || none }
                    />
                </div>
                <div className={ classNames(cnColQuarter, cnCcaButton) }>
                    <ButtonPlain
                        onClick={ e => {
                            e.preventDefault()
                            onConfirmInput(CCA_KEY)
                        } }
                        title={ i18n[COLD_CA].btnEnter }
                        disabled={ !cca || onlyMCA || none }
                    />
                </div>
            </div>
            <div className={ cnRow }>
                <div className={ cnCol }>
                    <Checkbox
                        description={ i18n[COLD_CA].checkboxMCA }
                        onChange={ () => setOnlyMCA(!onlyMCA) }
                        checked={ onlyMCA }
                        disabled={ none }
                    />
                </div>
            </div>
            <br />
            { onlyMCA && (
                <div className={ cnRow }>
                    <div className={ cnCol }>
                        <Input
                            title={ i18n[COLD_CA].mcaInput }
                            placeholder={ i18n[COLD_CA].inputPlaceholder }
                            controlledValue={ mca }
                            onChange={ v => setMca(v) }
                            type='number'
                            disabled={ none }
                        />
                    </div>
                    <div className={ classNames(cnColQuarter, cnCcaButton) }>
                        <ButtonPlain
                            onClick={ e => {
                                e.preventDefault()
                                onConfirmInput(CCA_KEY)
                            } }
                            title={ i18n[COLD_CA].btnEnter }
                            disabled={ !mca || none }
                        />
                    </div>
                </div>
            )}
            <div className={ cnRow }>
                <div className={ cnCol }>
                    <Checkbox
                        description={ i18n[COLD_CA].checkboxNone }
                        onChange={ () => setNone(!none) }
                        checked={ none }
                    />
                </div>
            </div>
        </>
    )
}