import { useDispatch, useSelector } from 'react-redux'
import { APPLICATION_MARINE } from '../../../data/strings'
import {
    addFormData,
    initMarineState,
    removeFormData,
    setFormData
} from '../dataReducer'

/**
 * 
 * @returns 
 */
export const useFormData = (application) => {
    const dispatch = useDispatch()

    // use 'camping' as default in case application is not provided
    const { formData } = useSelector(state => state.data[application ?? 'camping'])

    /**
     * 
     * @param {object} data 
     * @param {string?} app 
     */
    function setInputFormData (data, app) {
        dispatch(setFormData(app ?? application, data))
    }

    /**
     * 
     * @param {string} formName 
     * @param {string | object} data 
     */
     function addInputFormData (formName, data) {
        dispatch(addFormData(application, formName, data))
    }

    /**
     * 
     * @param {string} formName 
     */
    function deleteInputFormData (formName) {
        dispatch(removeFormData(application, formName))
    }

    /**
     * 
     * @param {string?} app 
     */
    function clearInputFormData (app) {
        setInputFormData(app === APPLICATION_MARINE ? initMarineState : {}, app)
    }

    return {
        formData,
        setInputFormData,
        addInputFormData,
        deleteInputFormData,
        clearInputFormData
    }
}