import { useTracking } from '@varta/digital-styleguide/build/src/utils/useTracking'
import { useResult } from '../redux/result/hooks/useResult'

/**
 * 
 * @returns 
 */
export const useCustomTracking = () => {
    const { track } = useTracking('LNA-Event')

    const { summary, recommendation, upgrade, note } = useResult()

    /**
     * 
     * @returns 
     */
    function formatSummaryForTracking () {
        let formatted = `activity-${ summary.activity }:vehicle-${ summary.VehicleType }${ summary.Application ? ':app-' + summary.Application : '' }`
        formatted = formatted + `${ recommendation ? ':recommendation-' + recommendation.details.short_code : '' }${ upgrade ? ':upgrade-' + upgrade.details.short_code : ''}${ note ? ':note-' + note : ''}`

        return formatted
    }

    /**
     * push formatted summary into datalayer
     */
    function trackSummary () {
        const formatted = formatSummaryForTracking()
        track({
            'function': 'LNA-Result',
            'type': formatted,
            'Seach-activity': summary.activity,
            'Seach-vehicle': summary.VehicleType,
            'Seach-app': summary.Application,
            'Seach-recommendation': recommendation,
            'Seach-upgrad': upgrade
        })
    }

    return {
        formatSummaryForTracking,
        trackSummary
    }
}