import React from 'react'

import {
    Checkbox,
    Headline
} from '@varta/digital-styleguide/build/src/components/01.atoms'
import {
    Form
} from '@varta/digital-styleguide/build/src/components/02.molecules'

import { useFormData } from '../../../redux/data/hooks/useFormData'
import { useNavigation } from '../../../redux/navigation/hooks/useNavigation'
import {
    APP_DUAL_USE,
    APP_START,
    APP_SUPPLY,
    APPLICATION,
    COLD_CA,
    NIGHTS,
    APPLICATION_MARINE,
} from '../../../data/strings'

import './_index.scss'

/**
 * 
 * @returns 
 */
export default function Application ({ i18n }) {

    const { formData, addInputFormData } = useFormData(APPLICATION_MARINE)
    const { setNextVisibleForm, setConfirmButton } = useNavigation(APPLICATION_MARINE)

    /**
     * 
     * @param {event} e 
     */
    function handleChange (e) {
        addInputFormData(APPLICATION, e.target.value)
        setConfirmButton(false)
        let nextForm = COLD_CA
        if (e.target.value === APP_SUPPLY) {
            nextForm = NIGHTS
        }
        setNextVisibleForm(APPLICATION, nextForm)
    }

    return (
        <>
            <Headline level={ 2 }>
                { i18n[APPLICATION].title }
            </Headline>
            <Form
                onChange={ handleChange }
            >
                <Checkbox
                    description={ i18n[APPLICATION].appStart }
                    type='radio'
                    value={ APP_START }
                    checked={ formData[APPLICATION] === APP_START }
                />
                <Checkbox
                    description={ i18n[APPLICATION].appSupply }
                    type='radio'
                    value={ APP_SUPPLY }
                    checked={ formData[APPLICATION] === APP_SUPPLY }
                />
                <Checkbox
                    description={ i18n[APPLICATION].appDualUse }
                    type='radio'
                    value={ APP_DUAL_USE }
                    checked={ formData[APPLICATION] === APP_DUAL_USE }
                />
            </Form>
        </>
    )
}