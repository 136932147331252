import React from 'react'

import { Headline } from '@varta/digital-styleguide/build/src/components/01.atoms'
import {
    CheckboxButton,
    Form
} from '@varta/digital-styleguide/build/src/components/02.molecules'

import { useFormData } from '../../../redux/data/hooks/useFormData'
import { useNavigation } from '../../../redux/navigation/hooks/useNavigation'
import {
    APPLICATION,
    APPLICATION_MARINE,
    TYPE_ELECTRIC,
    TYPE_FUELED,
    TYPE_SAILING,
    TYPE_YACHT,
    USAGE,
    VEHICLE_TYPE
} from '../../../data/strings'

import './_index.scss'

const CLASSNAME = 'varta-leisure-marine-wizard'
const cnRow = `${ CLASSNAME }__row`
const cnCol = `${ CLASSNAME }__col`
const cnColQuarter = `${ cnCol } ${ cnCol }--quarter`

/**
 * 
 * @returns 
 */
export default function VehicleType ({ i18n }) {

    const { formData, addInputFormData } = useFormData(APPLICATION_MARINE)
    const { setNextVisibleForm, setConfirmButton } = useNavigation(APPLICATION_MARINE)

    /**
     * 
     * @param {event} e 
     */
    function handleChange (e) {
        addInputFormData(VEHICLE_TYPE, e.target.value)
        setConfirmButton(false)
        let nextForm = APPLICATION
        if (e.target.value === TYPE_ELECTRIC) {
            nextForm = USAGE
        }
        setNextVisibleForm(VEHICLE_TYPE, nextForm)
    }

    return (
        <>
            <Headline level={ 2 }>
                { i18n[VEHICLE_TYPE].titleMarine }
            </Headline>
            <Form
                onChange={ handleChange }
            >
                <div className={ cnRow }>
                    <div className={ cnColQuarter }>
                        <CheckboxButton
                            title={ i18n[VEHICLE_TYPE].fueled }
                            value={ TYPE_FUELED }
                            selected={ formData[VEHICLE_TYPE] === TYPE_FUELED }
                        />
                    </div>
                    <div className={ cnColQuarter }>
                        <CheckboxButton
                            title={ i18n[VEHICLE_TYPE].electric }
                            value={ TYPE_ELECTRIC }
                            selected={ formData[VEHICLE_TYPE] === TYPE_ELECTRIC }
                        />
                    </div>
                    <div className={ cnColQuarter }>
                        <CheckboxButton
                            title={ i18n[VEHICLE_TYPE].sailing }
                            value={ TYPE_SAILING }
                            selected={ formData[VEHICLE_TYPE] === TYPE_SAILING }
                        />
                    </div>
                    <div className={ cnColQuarter }>
                        <CheckboxButton
                            title={ i18n[VEHICLE_TYPE].yacht }
                            value={ TYPE_YACHT }
                            selected={ formData[VEHICLE_TYPE] === TYPE_YACHT }
                        />
                    </div>
                </div>
            </Form>
        </>
    )
}