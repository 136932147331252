const languageMapping = {
    da: "da-DK",
    de: "de-DE",
    en: "en-GB",
    fr: "fr-FR",
    nl: "nl-NL",
    sv: "sv-SE"
}

/**
 * 
 * @returns 
 */
export const useLanguages = () => {
    const availableLanguages = Object.values(languageMapping)

    /**
     * 
     * @param {string} urlLocale 
     */
    function getLanguage (urlLocale) {
        let locale = navigator.language
        if (urlLocale?.length === 5 && availableLanguages.indexOf(urlLocale) !== -1) {
            locale = urlLocale
        } else if (urlLocale?.length === 2 && languageMapping[urlLocale]) {
            locale = languageMapping[urlLocale]
        } else {
            locale = process.env.REACT_APP_LOCALE
        }
        
        return locale
    }

    return {
        getLanguage
    }
}