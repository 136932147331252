import React from 'react'

import {
    CheckboxSlider,
    Form,
    HeadlineInfo
} from '@varta/digital-styleguide/build/src/components/02.molecules'

import { useFormData } from '../../../redux/data/hooks/useFormData'
import { useNavigation } from '../../../redux/navigation/hooks/useNavigation'
import { useSettings } from '../../../redux/settings/hooks/useSettings'

import {
    APPLICATION_CAMPING,
    MANEUVERS,
    MANEUVERS_LOW,
    MANEUVERS_HIGH
} from '../../../data/strings'

import './_index.scss'

const CLASSNAME = 'varta-leisure-camping-wizard'
const cnRow = `${ CLASSNAME }__row`

/**
 * 
 * @returns 
 */
export default function Maneuvers ({ i18n }) {

    const { formData, addInputFormData } = useFormData(APPLICATION_CAMPING)
    const { setConfirmButton } = useNavigation(APPLICATION_CAMPING)
    const { mobile } = useSettings()

    /**
     * 
     * @param {event} e 
     */
    function handleChange (e) {
        addInputFormData(MANEUVERS, e.target.value)
        setConfirmButton(true)
    }

    return (
        <>
            <HeadlineInfo level={2} info={ i18n[MANEUVERS].info }>
                { i18n[MANEUVERS].title }
            </HeadlineInfo>
            <Form
                onChange={ handleChange }
            >
                <div className={ cnRow }>
                    <CheckboxSlider
                        iconId='charger'
                        options={ [
                            { label: i18n[MANEUVERS].maneuversLow, value: MANEUVERS_LOW },
                            { label: i18n[MANEUVERS].maneuversHigh, value: MANEUVERS_HIGH },
                        ] }
                        selected={ formData[MANEUVERS] }
                        type={ mobile ? 'vertical' : 'horizontal' }
                    />
                </div>
            </Form>
        </>
    )
}