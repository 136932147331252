import { useDispatch, useSelector } from 'react-redux'

import { useEnergyDemand } from '../../data/hooks/useEnergyDemand'
import { useFormData } from '../../data/hooks/useFormData'
import {
    setShowConfirmButton,
    setShowTeaser,
    setTeaserContent,
    setVisibleForms
} from '../navigationReducer'
import {
    ENERGY_DEMAND,
    VEHICLE_TYPE
} from '../../../data/strings'

/**
 * 
 * @returns 
 */
export const useNavigation = (application) => {
    const dispatch = useDispatch()

    const { initializeEnergyDemand } = useEnergyDemand(application)
    const {
        formData,
        setInputFormData,
        deleteInputFormData
    } = useFormData(application)
    // use 'camping' as default in case application is not provided
    const {
        visibleForms,
        showConfirmButton,
        showTeaser,
        teaserContent
    } = useSelector(state => state.navigation[application ?? 'camping'])

    /**
     * 
     * @param {array} forms 
     */
    function setVisibleWizardForms (forms) {
        dispatch(setVisibleForms(application, forms))
    }

    /**
     * 
     * @param {string?} app 
     */
    function clearVisibleForms (app) {
        dispatch(setVisibleForms(app ?? application, [VEHICLE_TYPE]))
    }

    /**
     * 
     * @param {string} currentForm 
     * @param {string} nextForm 
     */
    function setNextVisibleForm (currentForm, nextForm) {
        const idx = visibleForms.indexOf(currentForm)
        const forms = nextForm ? [...visibleForms.splice(0, idx + 1), nextForm] : [...visibleForms.splice(0, idx + 1)]
        if (visibleForms.length > 0) {
            visibleForms.forEach(invalidForm => {
                deleteInputFormData(invalidForm)
            })
        }
        if (nextForm === ENERGY_DEMAND) {
            initializeEnergyDemand()
        }
        setVisibleWizardForms(forms)
    }

    /**
     * 
     * @param {bool} show 
     * @param {string?} app 
     */
    function setConfirmButton (show, app) {
        dispatch(setShowConfirmButton(app ?? application, show))
    }

    /**
     * 
     * @param {bool} show 
     */
    function setTeaser (show, app) {
        dispatch(setShowTeaser(app ?? application, show))
    }

    /**
     * 
     * @param {object} content 
     */
    function setWizardTeaserContent (content, app) {
        dispatch(setTeaserContent(app ?? application, content))
    }

    return {
        visibleForms,
        showConfirmButton: showConfirmButton || visibleForms.indexOf(ENERGY_DEMAND) !== -1,
        showTeaser,
        teaserContent,
        setVisibleWizardForms,
        clearVisibleForms,
        setNextVisibleForm,
        setConfirmButton,
        setTeaser,
        setWizardTeaserContent
    }
}