import React from 'react'

import {
    CheckboxButton,
    Form,
    HeadlineInfo
} from '@varta/digital-styleguide/build/src/components/02.molecules'

import { useFormData } from '../../../redux/data/hooks/useFormData'
import { useNavigation } from '../../../redux/navigation/hooks/useNavigation'
import {
    TECH_AGM,
    TECH_CONVENTIONAL,
    TECH_EFB,
    TECH_NA,
    BATTERY_SIZE,
    NIGHTS,
    START_STOP,
    TECHNOLOGY_USED,
    APPLICATION_CAMPING
} from '../../../data/strings'

import './_index.scss'

const CLASSNAME = 'varta-leisure-camping-wizard'
const cnRow = `${ CLASSNAME }__row`
const cnCol = `${ CLASSNAME }__col`
const cnColQuarter = `${ cnCol } ${ cnCol }--quarter`
const cnButtonFullWidth = `${ CLASSNAME }__button-full-width`

/**
 * 
 * @returns 
 */
export default function TechnologyUsed ({ i18n }) {

    const { formData, addInputFormData } = useFormData(APPLICATION_CAMPING)
    const { setNextVisibleForm, setConfirmButton } = useNavigation(APPLICATION_CAMPING)

    /**
     *
     * @param {event} e 
     */
    function handleChange (e) {
        addInputFormData(TECHNOLOGY_USED, e.target.value)
        setConfirmButton(false)
        let nextForm = START_STOP
        if (e.target.value === TECH_EFB) {
            nextForm = NIGHTS
        } else if (e.target.value === TECH_AGM) {
            nextForm = BATTERY_SIZE
        }
        setNextVisibleForm(TECHNOLOGY_USED, nextForm)
    }

    return (
        <>
            <HeadlineInfo level={2} info={ i18n[TECHNOLOGY_USED].info }>
                { i18n[TECHNOLOGY_USED].title }
            </HeadlineInfo>
            <Form
                onChange={ handleChange }
            >
                <div className={ cnRow }>
                    <div className={ cnColQuarter }>
                        <CheckboxButton
                            className={ cnButtonFullWidth }
                            title={ i18n[TECHNOLOGY_USED].conventional }
                            value={ TECH_CONVENTIONAL }
                            selected={ formData[TECHNOLOGY_USED] === TECH_CONVENTIONAL }
                        />
                    </div>
                    <div className={ cnColQuarter }>
                        <CheckboxButton
                            className={ cnButtonFullWidth }
                            title={ i18n[TECHNOLOGY_USED].efb }
                            value={ TECH_EFB }
                            selected={ formData[TECHNOLOGY_USED] === TECH_EFB }
                        />
                    </div>
                    <div className={ cnColQuarter }>
                        <CheckboxButton
                            className={ cnButtonFullWidth }
                            title={ i18n[TECHNOLOGY_USED].agm }
                            value={ TECH_AGM }
                            selected={ formData[TECHNOLOGY_USED] === TECH_AGM }
                        />
                    </div>
                    <div className={ cnColQuarter }>
                        <CheckboxButton
                            className={ cnButtonFullWidth }
                            title={ i18n[TECHNOLOGY_USED].na }
                            value={ TECH_NA }
                            selected={ formData[TECHNOLOGY_USED] === TECH_NA }
                        />
                    </div>
                </div>
            </Form>
        </>
    )
}