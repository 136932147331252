import React from 'react'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { Icons } from '@varta/digital-styleguide/build/src/components/00.helper'

import Router from './Router'
import reducers from './redux/reducers'

const store = createStore(reducers)

/**
 * 
 */
export default function LeisureNeedAnalyzer ({ locale, prefix }) {

    return (
        <Provider store={ store }>
            <Icons />
            <BrowserRouter>
                <Router locale={ locale } prefix={ prefix }/>
            </BrowserRouter>
        </Provider>
    )
}
