import React, { useEffect, useRef } from 'react'

import { Headline } from '@varta/digital-styleguide/build/src/components/01.atoms'
import {
    CheckboxButton,
    Form,
    Input
} from '@varta/digital-styleguide/build/src/components/02.molecules'

import { useBoatingUsage } from '../../../redux/data/hooks/useBoatingUsage'
import { useNavigation } from '../../../redux/navigation/hooks/useNavigation'
import {
    APPLICATION_MARINE,
    USAGE,
    USAGE_FULL_SPEED,
    USAGE_HALF_SPEED,
    USAGE_LOW_SPEED
} from '../../../data/strings'

import './_index.scss'

const CLASSNAME = 'varta-leisure-marine-wizard'
const cnRow = `${ CLASSNAME }__row`
const cnCol = `${ CLASSNAME }__col`
const cnColQuarter = `${ cnCol } ${ cnCol }--quarter`

/**
 * 
 * @returns 
 */
export default function Usage ({ i18n }) {

    const {
        boatingUsage,
        isSelected,
        addBoatingUsage,
        toggleBoatingUsage
    } = useBoatingUsage()
    const { setConfirmButton } = useNavigation(APPLICATION_MARINE)

    const formRef = useRef()

    useEffect(() => {
        setConfirmButton(false)
        const boatingValues = Object.values(boatingUsage)
        boatingValues.forEach(boatValue => {
            if (boatValue.valid && boatValue.duration && boatValue.power) {
                setConfirmButton(true)
                return    
            }
        });
    }, [ boatingUsage ])

    /**
     * 
     * @param {string} name 
     */
    function handleCheckboxClick (name) {
        toggleBoatingUsage(name, !isSelected(name))
        if (!isSelected(name)) {
            const myForm = formRef.current.form.current
            const child = myForm.querySelector(`input[name=${ name }]`)
            const child2 = myForm.querySelector(`input[name=${ name }-power]`)
            handleInputChange(name, 'duration', child.value)
            handleInputChange(name, 'power', child2.value)
        }
    }

    /**
     * 
     * @param {string} type 
     * @param {string} key 
     * @param {string} value 
     */
    function handleInputChange (type, key, value) {
        addBoatingUsage(type, { [key]: value })
    }

    return (
        <>
            <Headline level={ 2 }>
                { i18n[USAGE].title }
            </Headline>
            <Form ref={ formRef }>
                <div className={ cnRow }>
                    <div className={ cnCol }>
                        <CheckboxButton
                            title={ i18n[USAGE].fullSpeed }
                            value={ USAGE_FULL_SPEED }
                            selected={ isSelected(USAGE_FULL_SPEED) }
                            onChange={ () => handleCheckboxClick(USAGE_FULL_SPEED) }
                        />
                    </div>
                    <div className={ cnColQuarter }>
                        <Input
                            title={ i18n[USAGE].duration }
                            placeholder={ i18n[USAGE].durationPlaceholder }
                            controlledValue={ boatingUsage ? boatingUsage[USAGE_FULL_SPEED]?.duration : 0 }
                            disabled={ !isSelected(USAGE_FULL_SPEED) }
                            name={ USAGE_FULL_SPEED }
                            type='number'
                            onChange={ v => handleInputChange(USAGE_FULL_SPEED, 'duration', v) }
                        />
                    </div>
                    <div className={ cnColQuarter }>
                        <Input
                            title={ i18n[USAGE].power }
                            placeholder={ i18n[USAGE].powerPlaceholder }
                            controlledValue={ boatingUsage[USAGE_FULL_SPEED]?.power ?? 50 }
                            disabled={ !isSelected(USAGE_FULL_SPEED) }
                            name={USAGE_FULL_SPEED + '-power'}
                            type='number'
                            onChange={ v => handleInputChange(USAGE_FULL_SPEED, 'power', v) }
                        />
                    </div>
                </div>
                <br />
                <div className={ cnRow }>
                    <div className={ cnCol }>
                        <CheckboxButton
                            title={ i18n[USAGE].halfSpeed }
                            value={ USAGE_HALF_SPEED }
                            selected={ isSelected(USAGE_HALF_SPEED) }
                            onChange={ () => handleCheckboxClick(USAGE_HALF_SPEED) }
                        />
                    </div>
                    <div className={ cnColQuarter }>
                        <Input
                            title={ i18n[USAGE].duration }
                            placeholder={ i18n[USAGE].durationPlaceholder }
                            controlledValue={ boatingUsage ? boatingUsage[USAGE_HALF_SPEED]?.duration : 0 }
                            disabled={ !isSelected(USAGE_HALF_SPEED) }
                            name={ USAGE_HALF_SPEED }
                            type='number'
                            onChange={ v => handleInputChange(USAGE_HALF_SPEED, 'duration', v) }
                        />
                    </div>
                    <div className={ cnColQuarter }>
                        <Input
                            title={ i18n[USAGE].power }
                            placeholder={ i18n[USAGE].powerPlaceholder }
                            controlledValue={ boatingUsage[USAGE_HALF_SPEED]?.power ?? 25 }
                            disabled={ !isSelected(USAGE_HALF_SPEED) }
                            name={USAGE_HALF_SPEED + '-power'}
                            type='number'
                            onChange={ v => handleInputChange(USAGE_HALF_SPEED, 'power', v) }
                        />
                    </div>
                </div>
                <br />
                <div className={ cnRow }>
                    <div className={ cnCol }>
                        <CheckboxButton
                            title={ i18n[USAGE].lowSpeed }
                            value={ USAGE_LOW_SPEED }
                            selected={ isSelected(USAGE_LOW_SPEED) }
                            onChange={ () => handleCheckboxClick(USAGE_LOW_SPEED) }
                        />
                    </div>
                    <div className={ cnColQuarter }>
                        <Input
                            title={ i18n[USAGE].duration }
                            placeholder={ i18n[USAGE].durationPlaceholder }
                            controlledValue={ boatingUsage ? boatingUsage[USAGE_LOW_SPEED]?.duration : 0 }
                            disabled={ !isSelected(USAGE_LOW_SPEED) }
                            name={ USAGE_LOW_SPEED }
                            type='number'
                            onChange={ v => handleInputChange(USAGE_LOW_SPEED, 'duration', v) }
                        />
                    </div>
                    <div className={ cnColQuarter }>
                        <Input
                            title={ i18n[USAGE].power }
                            placeholder={ i18n[USAGE].powerPlaceholder }
                            controlledValue={ boatingUsage[USAGE_LOW_SPEED]?.power ?? 10 }
                            disabled={ !isSelected(USAGE_LOW_SPEED) }
                            name={USAGE_LOW_SPEED + '-power'}
                            type='number'
                            onChange={ v => handleInputChange(USAGE_LOW_SPEED, 'power', v) }
                        />
                    </div>
                </div>
            </Form>
        </>
    )
}