import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router'

import NotFoundView from './views/NotFoundView'
import ResultView from './views/ResultView'
import StartView from './views/StartView'
import WizardCamping from './views/WizardCamping/WizardCamping'
import WizardMarine from './views/WizardMarine/WizardMarine'

/**
 * 
 * @returns 
 */
export default function Router ({ locale, prefix }) {
    const { pathname } = useLocation()

    return (
        <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <Route exact path='/:locale?' >
                <StartView />
            </Route>
            <Route exact path={ `/:locale${ prefix }/camping` }>
                <WizardCamping />
            </Route>
            <Route exact path={ `/:locale${ prefix }/marine` } >
                <WizardMarine />
            </Route>
            <Route exact path={ `/:locale${ prefix }/result` } >
                <ResultView />
            </Route>
            <Route path='*'>
                <NotFoundView />
            </Route>
        </Switch>
    )
}