import { useDispatch, useSelector } from "react-redux"
import { addUsage, toggleUsage } from "../dataReducer"
import { USAGE } from "../../../data/strings"

/**
 * 
 * @returns 
 */
export const useBoatingUsage = () => {
    const dispatch = useDispatch()

    const boatingUsage = useSelector(state => state.data.marine.formData[USAGE])

    /**
     * 
     * @param {string} key 
     */
     function isSelected (key) {
        if (!boatingUsage) {
            return false
        } else if (boatingUsage[key].valid) {
            return true
        }
        return false
    }

    /**
     * 
     * @param {object} usage 
     */
    function addBoatingUsage (key, usage) {
        dispatch(addUsage(key, usage))
    }

    /**
     * 
     * @param {string} key 
     * @param {boolean} valid 
     */
    function toggleBoatingUsage (key, valid) {
        dispatch(toggleUsage(key, valid))
    }

    return {
        boatingUsage,
        isSelected,
        addBoatingUsage,
        toggleBoatingUsage
    }
}