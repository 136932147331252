import React, { useEffect } from 'react'

import {
    ButtonLink,
    Divider,
    Headline
} from '@varta/digital-styleguide/build/src/components/01.atoms'

import { AdditionalConsumer } from '../../../components/AdditionalConsumer'
import { EnergyConsumer } from '../../../components/EnergyConsumer'
import { useFormData } from '../../../redux/data/hooks/useFormData'
import { useEnergyDemand } from '../../../redux/data/hooks/useEnergyDemand'
import { useNavigation } from '../../../redux/navigation/hooks/useNavigation'
import {
    APPLICATION_MARINE,
    ENERGY_DEMAND,
    ENERGY_FRIDGE,
    ENERGY_GPS,
    ENERGY_KETTLE,
    ENERGY_LIGHTS,
    ENERGY_MOBILE,
    ENERGY_RADIO,
    ENERGY_TABLET,
    ENERGY_TV,
    ENERGY_WATER_PUMP
} from '../../../data/strings'

import './_index.scss'

const CLASSNAME = 'varta-leisure-marine-wizard'
const cnRow = `${ CLASSNAME }__row`
const cnCol = `${ CLASSNAME }__col`

/**
 * 
 * @returns 
 */
export default function EnergyDemand ({ i18n }) {

    const {
        additionalDemand,
        isSelected,
        initializeEnergyDemand,
        addAdditionalDemand,
        setAdditionalDemand,
        removeAdditionalDemand
    } = useEnergyDemand(APPLICATION_MARINE)
    const { formData } = useFormData(APPLICATION_MARINE)
    const { setConfirmButton } = useNavigation(APPLICATION_MARINE)

    const additional = i18n[ENERGY_DEMAND].energyAdditional

    // initialize energy demand object in reducer
    useEffect(() => {
        if (!formData[ENERGY_DEMAND]) {
            initializeEnergyDemand()
        }
        setConfirmButton(true)
    }, [])

    /**
     * custom energy demands with only input fields
     * 
     * @param {object} demand { key, value }
     * @param {number} idx 
     */
    function handleAdditionalDemandChanged (demand, idx) {
        setAdditionalDemand(demand, idx)
    }

    return (
        <>
            <Headline level={ 2 }>
                { i18n[ENERGY_DEMAND].title }
            </Headline>
            <EnergyConsumer
                i18n={ i18n[ENERGY_DEMAND] }
                application={ APPLICATION_MARINE }
                iconId='water-pump'
                value={ ENERGY_WATER_PUMP }
                selected={ isSelected(ENERGY_WATER_PUMP) }
                inputElementType='input'
                inputType='number'
                inputValue={ formData[ENERGY_DEMAND]?.waterpump?.input ?? 20 }
            />
            <EnergyConsumer
                i18n={ i18n[ENERGY_DEMAND] }
                application={ APPLICATION_MARINE }
                iconId='kettle'
                value={ ENERGY_KETTLE }
                selected={ isSelected(ENERGY_KETTLE) }
                inputElementType='counter'
                inputValue={ formData[ENERGY_DEMAND]?.kettle?.input ?? 1 }
            />
            <EnergyConsumer
                i18n={ i18n[ENERGY_DEMAND] }
                application={ APPLICATION_MARINE }
                iconId='tv'
                value={ ENERGY_TV }
                selected={ isSelected(ENERGY_TV) }
                inputElementType='input'
                inputType='number'
                inputValue={ formData[ENERGY_DEMAND]?.tv?.input ?? 20 }
            />
            <EnergyConsumer
                i18n={ i18n[ENERGY_DEMAND] }
                application={ APPLICATION_MARINE }
                iconId='fridge'
                value={ ENERGY_FRIDGE }
                selected={ isSelected(ENERGY_FRIDGE) }
                inputElementType='input'
                inputType='number'
                inputValue={ formData[ENERGY_DEMAND]?.fridge?.input ?? 40 }
            />
            <EnergyConsumer
                i18n={ i18n[ENERGY_DEMAND] }
                application={ APPLICATION_MARINE }
                iconId='mobile-phone'
                value={ ENERGY_MOBILE }
                selected={ isSelected(ENERGY_MOBILE) }
                inputElementType='counter'
                inputValue={ formData[ENERGY_DEMAND]?.mobile?.input ?? 1 }
            />
            <EnergyConsumer
                i18n={ i18n[ENERGY_DEMAND] }
                application={ APPLICATION_MARINE }
                iconId='tablet'
                value={ ENERGY_TABLET }
                selected={ isSelected(ENERGY_TABLET) }
                inputElementType='counter'
                inputValue={ formData[ENERGY_DEMAND]?.tablet?.input ?? 1 }
            />
            <EnergyConsumer
                i18n={ i18n[ENERGY_DEMAND] }
                application={ APPLICATION_MARINE }
                iconId='gps'
                value={ ENERGY_GPS }
                selected={ isSelected(ENERGY_GPS) }
                inputElementType='select'
                inputValue={ formData[ENERGY_DEMAND]?.gps?.input }
            />
            <EnergyConsumer
                i18n={ i18n[ENERGY_DEMAND] }
                application={ APPLICATION_MARINE }
                iconId='radio'
                value={ ENERGY_RADIO }
                selected={ isSelected(ENERGY_RADIO) }
                inputElementType='select'
                inputValue={ formData[ENERGY_DEMAND]?.radio?.input }
            />
            <EnergyConsumer
                i18n={ i18n[ENERGY_DEMAND] }
                application={ APPLICATION_MARINE }
                iconId='lights'
                value={ ENERGY_LIGHTS }
                selected={ isSelected(ENERGY_LIGHTS) }
                inputElementType='counter'
                inputValue={ formData[ENERGY_DEMAND]?.lights?.input }
                additionalElementType='select'
                additionalValue={ formData[ENERGY_DEMAND]?.lights?.additional }
            />
            <div style={{ height: '3em' }} />
            <div className={ cnRow }>
                <div className={ cnCol }>
                    { i18n[ENERGY_DEMAND].titleAddDemand }
                </div>
            </div>
            <Divider />
            { additionalDemand && additionalDemand.map(function (demand, idx) {
                return (
                    <AdditionalConsumer
                        key={ `${ demand }-${ idx }` }
                        i18n={ additional }
                        demand={ demand }
                        deletable={ additionalDemand.length > 1 }
                        onDeleteClicked={ () => removeAdditionalDemand(idx) }
                        handleChange={ demand => handleAdditionalDemandChanged(demand, idx) }
                    />
                )
            }) }
            <ButtonLink
                title={ additional.btnAddDemand }
                type="secondary"
                iconLeft="plus"
                onClick={ addAdditionalDemand }
                link={ undefined }
            />
        </>
    )
}