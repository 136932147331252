import React from 'react'

import { Headline } from '@varta/digital-styleguide/build/src/components/01.atoms'
import {
    CheckboxSlider,
    Form
} from '@varta/digital-styleguide/build/src/components/02.molecules'

import { useFormData } from '../../../redux/data/hooks/useFormData'
import { useNavigation } from '../../../redux/navigation/hooks/useNavigation'
import { useSettings } from '../../../redux/settings/hooks/useSettings'

import {
    APPLICATION,
    APPLICATION_MARINE,
    APP_DUAL_USE,
    ENERGY_DEMAND,
    NIGHTS,
    NIGHTS_ONE,
    NIGHTS_THREE,
    NIGHTS_TWO,
    NIGHTS_TWO_MORE,
    SOLAR_PANEL
} from '../../../data/strings'

import './_index.scss'

const CLASSNAME = 'varta-leisure-marine-wizard'
const cnRow = `${ CLASSNAME }__row`

/**
 * 
 * @returns 
 */
export default function Nights ({ i18n }) {

    const { formData, addInputFormData } = useFormData(APPLICATION_MARINE)
    const { setNextVisibleForm, setConfirmButton } = useNavigation(APPLICATION_MARINE)
    const { mobile } = useSettings()

    const nightsOptions = formData[APPLICATION] === APP_DUAL_USE ? [
        { label: i18n[NIGHTS].nightsOne, value: NIGHTS_ONE },
        { label: i18n[NIGHTS].nightsTwoMore, value: NIGHTS_TWO_MORE }
    ] : [
        { label: i18n[NIGHTS].nightsOne, value: NIGHTS_ONE },
        { label: i18n[NIGHTS].nightsTwo, value: NIGHTS_TWO },
        { label: i18n[NIGHTS].nightsThreeMore, value: NIGHTS_THREE }
    ]

    /**
     * 
     * @param {event} e 
     */
    function handleChange (e) {
        addInputFormData(NIGHTS, e.target.value)
        setConfirmButton(false)
        let nextForm = e.target.value === NIGHTS_ONE ? SOLAR_PANEL : ENERGY_DEMAND
        setNextVisibleForm(NIGHTS, nextForm)
    }

    return (
        <>
            <Headline level={ 2 }>
                { i18n[NIGHTS].title }
            </Headline>
            <Form
                onChange={ handleChange }
            >
                <div className={ cnRow }>
                    <CheckboxSlider
                        iconId='night-sky'
                        options={ nightsOptions }
                        selected={ formData[NIGHTS] }
                        type={mobile ? 'vertical' : 'horizontal'}
                        />
                </div>
            </Form>
        </>
    )
}