// applications
export const ACTIVITY = 'activity'
export const APPLICATION_CAMPING = 'camping'
export const APPLICATION_MARINE = 'marine'

// camping form names
export const APPLICATION = 'Application'
export const BATTERY_SIZE = 'BatterySize'
export const SOLAR_PANEL = 'SolarPanel'
export const ENERGY_DEMAND = 'EnergyDemand'
export const NIGHTS = 'Nights'
export const START_STOP = 'StartStop'
export const TECHNOLOGY_USED = 'TechnologyUsed'
export const MANEUVERS = 'Maneuvers'
export const VEHICLE_TYPE = 'VehicleType'

// camping form values
export const TYPE_MOTORHOME = 'motorhome'
export const TYPE_CARAVAN = 'caravan'

export const APP_START = 'start'
export const APP_SUPPLY = 'supply'
export const APP_DUAL_USE = 'dual'
export const APP_MOVER = 'mover'

export const TECH_CONVENTIONAL = 'conventional'
export const TECH_EFB = 'efb'
export const TECH_AGM = 'agm'
export const TECH_NA = 'na'

export const START_STOP_YES = 'start_stop'
export const START_STOP_NO = 'no_start_stop'

export const NIGHTS_ONE = 'one_night'
export const NIGHTS_TWO = 'two_nights'
export const NIGHTS_TWO_MORE = 'two_nights_more'
export const NIGHTS_THREE = 'three_nights'

export const SOLAR_PANEL_NO = 'no_solar'
export const SOLAR_PANEL_YES = 'yes_solar'

export const SIZE_TINY = '207'
export const SIZE_SMALL = '242'
export const SIZE_MEDIUM = '278'
export const SIZE_LARGE = '353'
export const SIZE_EXTRA = '394'

export const ENERGY_WATER_PUMP = 'waterpump'
export const ENERGY_KETTLE = 'kettle'
export const ENERGY_TV = 'tv'
export const ENERGY_FRIDGE = 'fridge'
export const ENERGY_MOBILE = 'mobile'
export const ENERGY_TABLET = 'tablet'

export const MANEUVERS_LOW = 'low'
export const MANEUVERS_HIGH = 'high'


// additional marine form names
export const COLD_CA = 'CCA'
export const USAGE = 'Usage'

// additional marine values
export const TYPE_FUELED = 'fueled'
export const TYPE_ELECTRIC = 'electric'
export const TYPE_SAILING = 'sailing'
export const TYPE_YACHT = 'yacht'

export const CCA_KEY = 'cca'
export const MCA_KEY = 'mca'

export const ENERGY_GPS = 'gps'
export const ENERGY_RADIO = 'radio'
export const ENERGY_LIGHTS = 'lights'

export const USAGE_FULL_SPEED = 'full_speed'
export const USAGE_HALF_SPEED = 'half_speed'
export const USAGE_LOW_SPEED = 'low_speed'


// teaser
export const TEASER_CONTACT_MANUFACTURER = 'TEASER_CONTACT_MANUFACTURER'
export const TEASER_CHECK_INPUT = 'TEASER_CHECK_INPUT'
export const TEASER_VISIT_BATTERY_FINDER = 'TEASER_VISIT_BATTERY_FINDER'

// notes
export const NOTE_TOO_MANY_CONSUMERS = 'NOTE_TOO_MANY_CONSUMERS'
export const NOTE_SIZE_NOT_SUFFICIENT = 'NOTE_SIZE_NOT_SUFFICIENT'
export const NOTE_FOR_CARS_ONLY = 'NOTE_FOR_CARS_ONLY'
export const NOTE_CHECK_AVAILABLE_SPACE = 'NOTE_CHECK_AVAILABLE_SPACE'
export const NOTE_TOO_MANY_MOVINGS = 'NOTE_TOO_MANY_MOVINGS'
export const NOTE_CHECK_BATTERY_SIZE = 'NOTE_CHECK_BATTERY_SIZE'
export const NOTE_REDUCE_SPEED = 'NOTE_REDUCE_SPEED'