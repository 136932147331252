import { useDispatch, useSelector } from 'react-redux'

import {
    toggleEnergyValid,
    setEnergyDemand,
    initEnergyDemand,
    addCustomDemand,
    deleteCustomDemand,
    setCustomDemand
} from '../dataReducer'
import {
    ENERGY_DEMAND
} from '../../../data/strings'

/**
 * 
 * @returns 
 */
export const useEnergyDemand = (application) => {

    const dispatch = useDispatch()

    const { formData } = useSelector(state => state.data[application ?? 'camping'])
    const { additionalDemand } = formData[ENERGY_DEMAND] ?? [{}]

    /**
     * 
     * @param {string} key 
     */
    function isSelected (key) {
        const energyForm = formData[ENERGY_DEMAND]
        if (energyForm && energyForm[key] && energyForm[key].valid === true) {
            return true
        }

        return false
    }

    /**
     * 
     */
    function initializeEnergyDemand () {
        dispatch(initEnergyDemand(application))
    }

    /**
     * 
     * @param {string} key 
     * @param {object} data 
     */
    function addEnergyDemand (key, data) {
        dispatch(setEnergyDemand(application, key, {
            ...data,
            valid: true
        }))
    }

    /**
     * 
     * @param {string} demand 
     * @param {bool} valid 
     */
    function toggleEnergyDemand (demand, valid) {
        dispatch(toggleEnergyValid(application, demand, valid))
    }

    /**
     * 
     */
    function addAdditionalDemand () {
        dispatch(addCustomDemand(application))
    }

    /**
     * 
     * @param {object} demand 
     * @param {number} idx 
     */
    function setAdditionalDemand (demand, idx) {
        const myDemand = additionalDemand[idx ?? (additionalDemand.length - 1)]
        const newDemand = {
            ...myDemand,
            [demand.key]: demand.value
        }
        dispatch(setCustomDemand(application, idx, newDemand))
    }
    
    /**
     * 
     * @param {number} idx 
     */
    function removeAdditionalDemand (idx) {
        dispatch(deleteCustomDemand(application, idx))
    }

    return {
        additionalDemand,
        isSelected,
        initializeEnergyDemand,
        addEnergyDemand,
        toggleEnergyDemand,
        addAdditionalDemand,
        setAdditionalDemand,
        removeAdditionalDemand
    }
}