import { createActions, handleActions } from 'redux-actions'

import {
    ENERGY_DEMAND,
    USAGE,
    USAGE_FULL_SPEED,
    USAGE_HALF_SPEED,
    USAGE_LOW_SPEED
} from '../../data/strings'

export const initMarineState = {
    [USAGE]: {
        [USAGE_FULL_SPEED]: {},
        [USAGE_HALF_SPEED]: {},
        [USAGE_LOW_SPEED]: {}
    }
}

const initState = {
    camping: {
        formData: {}
    },
    marine: {
        formData: initMarineState
    }
}

export const {
    setFormData,
    addFormData,
    removeFormData,
    initEnergyDemand,
    setEnergyDemand,
    toggleEnergyValid,
    addCustomDemand,
    setCustomDemand,
    deleteCustomDemand,
    addUsage,
    toggleUsage
} = createActions({
    SET_FORM_DATA: (application, data) => ({ application, data }),
    ADD_FORM_DATA: (application, key, data) => ({ application, key, data }),
    REMOVE_FORM_DATA: (application, key) => ({ application, key }),
    INIT_ENERGY_DEMAND: application => ({ application }),
    SET_ENERGY_DEMAND: (application, demand, data) => ({ application, demand, data }),
    TOGGLE_ENERGY_VALID: (application, demand, valid) => ({ application, demand, valid }),
    ADD_CUSTOM_DEMAND: (application) => ({ application }),
    SET_CUSTOM_DEMAND: (application, idx, demand) => ({ application, idx, demand }),
    DELETE_CUSTOM_DEMAND: (application, idx) => ({ application, idx }),
    ADD_USAGE: (key, usage) => ({ key, usage }),
    TOGGLE_USAGE: (key, valid) => ({ key, valid })
})

export const reducer = handleActions(
    {
        [ setFormData ]: (state, { payload: { application, data } }) => ({
            ...state,
            [application]: {
                formData: data
            }
        }),
        [ addFormData ]: (state, { payload: { application, key, data } }) => ({
            ...state,
            [application]: {
                ...state[application],
                formData: {
                    ...state[application].formData,
                    [key]: data
                }
            }
        }),
        [ removeFormData ]: (state, { payload: { application, key }}) => {
            const oldFormData = state[application].formData
            delete oldFormData[key]
            return {
                ...state,
                [application]: {
                    ...state[application],
                    formData: oldFormData
                }
            }
        },
        [ initEnergyDemand ]: (state, { payload: { application }}) => ({
            ...state,
            [application]: {
                ...state[application],
                formData: {
                    ...state[application].formData,
                    [ENERGY_DEMAND]: {
                        additionalDemand: [{
                            device: '',
                            power: '',
                            usage: '',
                        }]
                    }
                }
            }
        }),
        [ setEnergyDemand ]: (state, { payload: { application, demand, data }}) => ({
            ...state,
            [application]: {
                ...state[application],
                formData: {
                    ...state[application].formData,
                    [ENERGY_DEMAND]: {
                        ...state[application].formData[ENERGY_DEMAND],
                        [demand]: {
                            ...state[application].formData[ENERGY_DEMAND][demand],
                            ...data
                        }
                    }
                }
            }
        }),
        [ toggleEnergyValid ]: (state, { payload: { application, demand, valid }}) => ({
            ...state,
            [application]: {
                ...state[application],
                formData: {
                    ...state[application].formData,
                    [ENERGY_DEMAND]: {
                        ...state[application].formData[ENERGY_DEMAND],
                        [demand]: {
                            ...state[application].formData[ENERGY_DEMAND][demand],
                            valid
                        }
                    }
                }
            }
        }),
        [ addCustomDemand ]: (state, { payload: { application }}) => ({
            ...state,
            [application]: {
                ...state[application],
                formData: {
                    ...state[application].formData,
                    [ENERGY_DEMAND]: {
                        ...state[application].formData[ENERGY_DEMAND],
                        additionalDemand: [
                            ...state[application].formData[ENERGY_DEMAND].additionalDemand,
                            {
                                device: '',
                                power: '',
                                usage: '',
                            }
                        ]
                    }
                }
            }
        }),
        [ setCustomDemand ]: (state, { payload: { application, idx, demand }}) => ({
            ...state,
            [application]: {
                ...state[application],
                formData: {
                    ...state[application].formData,
                    [ENERGY_DEMAND]: {
                        ...state[application].formData[ENERGY_DEMAND],
                        additionalDemand: [
                            ...state[application].formData[ENERGY_DEMAND].additionalDemand.slice(0, idx),
                            demand,
                            ...state[application].formData[ENERGY_DEMAND].additionalDemand.slice(idx + 1),
                        ]
                    }
                }
            }
        }),
        [ deleteCustomDemand ]: (state, { payload: { application, idx }}) => {
            const newDemand = [
                ...state[application].formData[ENERGY_DEMAND].additionalDemand.slice(0, idx),
                ...state[application].formData[ENERGY_DEMAND].additionalDemand.slice(idx + 1)
            ]
            return {
                ...state,
                [application]: {
                    ...state[application],
                    formData: {
                        ...state[application].formData,
                        [ENERGY_DEMAND]: {
                            ...state[application].formData[ENERGY_DEMAND],
                            additionalDemand: newDemand
                        }
                    }
                }
            }
        },
        [ addUsage ]: (state, { payload: { key, usage }}) => ({
            ...state,
            marine: {
                ...state.marine,
                formData: {
                    ...state.marine.formData,
                    [USAGE]: {
                        ...state.marine.formData[USAGE],
                        [key]: {
                            ...state.marine.formData[USAGE][key],
                            ...usage
                        }
                    }
                }
            }
        }),
        [ toggleUsage ]: (state, { payload: { key, valid }}) => ({
            ...state,
            marine: {
                ...state.marine,
                formData: {
                    ...state.marine.formData,
                    [USAGE]: {
                        ...state.marine.formData[USAGE],
                        [key]: {
                            ...state.marine.formData[USAGE][key],
                            valid
                        }
                    }
                }
            }
        })
    },
    initState
)